import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./assets/css/modal.css";


export type AuditLogPopupProps = {
    isOpen?: boolean
    onClose?: () => void
    initialData?: any
}

export function AuditLogPopup(props: AuditLogPopupProps) {
    let isOpen = props.isOpen || false;
    let initialData = props.initialData || [];


    const handleClose = () => {
        if (props.onClose != null) {
            props.onClose()
        }
    };

    return (
        <Modal show={isOpen} size="lg">
            <Modal.Header>
                <Modal.Title>Activity Logs</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                    <table style={{ minWidth: 650 }} aria-label="simple table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Notes</th>
                                <th align="right">User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {initialData.map((log, idx) => (
                                <tr key={idx} style={{ border: 0 }}>
                                    <td scope="row">
                                        {new Date(log.creationDate).toDateString()}
                                    </td>
                                    <td>{log.changeText}</td>
                                    <td align="right">{log.creatorEmail}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} className="btn btn-light">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )

} 
import React, {useEffect, useState} from "react";
import { useAuthFetch } from './useAuthFetch';
import { getApiUrl } from "./authConfig";
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import { AssetObject } from "./common/types";

import "./assets/css/modal.css";

const statuses = ["Not Commissioned", "Decommissioned", "Pass", "Fail"];
const deploymentPhases = ["Warehouse", "Vendor", "Contractor", "Building"]; 
const assetTypes = ["Water", "Gas", "Electricity", "Thermal", "Steam", "Communication"];
const commissioningItems = ["No Commissioning", "Commissioning", "SE-04"];

export type CreateAssetDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: AssetObject
    buildingId?: string
    productList?: Array<any>
    productFamilies?: Array<any>
    defaultTemplates?: Array<any>
    assetSuccess(successStatus: string): void
} 

export const CreateAssetDialog = observer((props: CreateAssetDialogProps)=> {
    const authFetch = useAuthFetch(); 
    const [modalOpen, setModalOpen] = useState(false);
    const [prodFamilySorted, setProdFamilySorted] = useState([]);

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;
    let buildingId = props.buildingId == null?  "": props.buildingId;


    var productList = props.productList || []; 
    var productFamilies = props.productFamilies || [];
    var defaultTemplates = props.defaultTemplates || [];

    productFamilies = productFamilies.filter(it => {
        let productIdx = productList.findIndex(prd => prd.productFamily == it.id);
        if(productIdx  == -1) {
            return false;
        }
        return true; 
    })

    productList = productList.sort((a, b) => a.productFamily.localeCompare(b.productFamily));

    const handleModalSuccessClose = () => { handleClose() };

    const handleModalErrorClose = () => { setModalOpen(false); };

    var initialData = props.initialData || {
        deploymentPhase: "Warehouse", 
        assetType: "Water", 
        status: "Not Commissioned", 
        commissioning: "False", 
        model: "",
        manufacturer: "", 
        productCode: "",
        productFamilyId: "",
        serialNumber: "",
        commSerialNumber: "",
        defaultTemplate: ""
    }

    const formData = useLocalObservable(()=> ({ 
        deploymentPhase: "Warehouse", 
        assetType: "Water", 
        status: "Not Commissioned", 
        commissioning: "False", 
        model: "",
        manufacturer: "", 
        productCode: "",
        productFamilyId: "",
        serialNumber: "",
        commSerialNumber: "",
        defaultTemplate: ""
    }))

    const setInitialData = () => {
        formData.deploymentPhase = initialData.deploymentPhase;
        formData.assetType =  initialData.assetType;
        formData.status =  initialData.status;
        formData.commissioning = initialData.commissioning; 
        formData.model =  initialData.model;
        formData.manufacturer =  initialData.manufacturer;
        formData.productCode = initialData.productCode;
        formData.productFamilyId = initialData.productFamilyId;
        formData.serialNumber = initialData.serialNumber;
        formData.commSerialNumber = initialData.commSerialNumber;
        formData.defaultTemplate = initialData.defaultTemplate;

        if(initialData.productCode == "" && productList.length != 0 ) {
            let prd = productList[0]; 
            formData.assetType =  prd.assetType;
            formData.commissioning = prd.commissioning; 
            formData.model =  prd.model;
            formData.manufacturer =  prd.manufacturer;
            formData.productCode = prd.productCode;
            formData.productFamilyId = prd.productFamilyId;
            formData.defaultTemplate = prd.defaultTemplate;
        }
    }

    useEffect(() => { setInitialData() }, [props.initialData]);
    useEffect(() => { sortProdFamily(); }, [productList]);

    const sortProdFamily = () => {
        setProdFamilySorted(Object.entries(
            productList.reduce((prev, curr) => {
                let prdFam = productFamilies.find(it => it.id == curr.productFamily).name.toUpperCase();
                prev[prdFam]
                    ? prev[prdFam].push(curr)
                    : (prev[prdFam] = [curr]);
                return prev;
            }, {})
        ));
    }

    const formError = useLocalObservable(()=> ({
        model: "",
        manufacturer: "", 
        serialNumber: "",
        commSerialNumber: ""
        
    }))

    const clearErrors = ()=> {
        formError.model = ""; 
        formError.manufacturer = ""; 
        formError.serialNumber = "";
        formError.commSerialNumber= "";
    }

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async () => {

        let hasError = false;
        clearErrors();

        if(formData.model == "") {
            hasError = true; 
            formError.model = "Asset model is required";  
        }

        if(formData.manufacturer == "") {
            hasError = true; 
            formError.manufacturer = "Asset manufacturer is required";
        }

        if(hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT";
        let url = getApiUrl("assets"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, {...data, buildingId: buildingId})

        if(fetchResponse.status != 409) {
            if(props.onSubmit != null) {
                props.onSubmit("success");
                props.assetSuccess(method);
            }
        } else {
            setModalOpen(true)
        }
    }

    const handleSubmitPlus = async () => {

       let hasError = false;
        clearErrors();

        if (formData.model == "") {
            hasError = true;
            formError.model = "Asset model is required";
        }

        if (formData.manufacturer == "") {
            hasError = true;
            formError.manufacturer = "Asset manufacturer is required";
        }

        if (hasError) {
            return;
        }

        let data = toJS(formData);


        let method = existingId == "" ? "POST" : "PUT";
        let url = getApiUrl("assets");

        if (existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, { ...data, buildingId: buildingId })

        if (fetchResponse.status != 409) {
            formData.serialNumber = "";
            props.assetSuccess(method);
        } else {
            setModalOpen(true)
        }
    } 

    const title = existingId == ""? "Create Asset": "Update Asset";

    const handleProductSelect = (productCode: string) => {
        console.log(productCode);
        formData.productCode = productCode;

        let selectedProduct = props.productList.find(it => it.productCode == productCode); 
        if(selectedProduct != null) {
            formData.assetType =  selectedProduct.assetType;
            formData.commissioning = selectedProduct.commissioning; 
            formData.model =  selectedProduct.model;
            formData.manufacturer =  selectedProduct.manufacturer;
            formData.productCode = selectedProduct.productCode;
            formData.productFamilyId = selectedProduct.productFamily;
            formData.defaultTemplate = selectedProduct.defaultTemplate               ;
        }
    }

    const handleProductFamilySelect = (pfamily: string) => {
        formData.productFamilyId = pfamily;

        let prd = productList.find(it => it.productFamily == pfamily); 

        if(prd != null) {
            formData.productCode = prd.productCode;
        }
    }

    return (
        <>
        <Modal show={isOpen} size="lg">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={{ marginBottom: '12px' }}>Please enter the information about the asset</div>
                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Product Family</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.productFamilyId == null || productFamilies.find(it => it.id == formData.productFamilyId) == null ? "" : productFamilies.find(it => it.id == formData.productFamilyId).name}>
                            {productFamilies.map((data, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={() => { handleProductFamilySelect(data.id); }}>{data.name}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Product Code</div>
                        <DropdownButton key="prodCode" className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.productCode == null || productList.find(it => it.productCode == formData.productCode) == null ? "" : productList.find(it => it.productCode == formData.productCode).productCode}>
                            {prodFamilySorted.map(([key, value]) => {
                                let prdFam = "";
                                    return (
                                        <div key={"drop1-"+key}>
                                            {prdFam != key && <div style={{ color: "lightblue", fontWeight: "bold", marginLeft: "4px" }}>{key}</div>}
                                            {value.map(({productCode}) => (
                                                <Dropdown.Item key={"drop2-" + productCode} onClick={(event: any, newValue) => {
                                                    handleProductSelect(productCode);
                                                }}>
                                                    {productCode}
                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                );
                                prdFam = key;
                                })
                            }
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Serial Number</div>
                        <Form.Control className="modal-input" size="lg" maxLength={32} type="text" placeholder={formData.serialNumber} onChange={e => formData.serialNumber = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Deployment Phase</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.deploymentPhase == null ? "" : formData.deploymentPhase}>
                            {deploymentPhases.map((phase) => {
                                return (
                                    <Dropdown.Item onClick={e => formData.deploymentPhase = phase} key={phase}>{phase}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Commissioning</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.commissioning == null ? "" : formData.commissioning}>
                            {commissioningItems.map((com) => {
                                return (
                                    <Dropdown.Item onClick={e => formData.commissioning = com} key={com}>{com}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Status</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.status == null ? "" : formData.status}>
                            {statuses.map((status) => {
                                return (
                                    <Dropdown.Item onClick={e => formData.status = status} key={status}>{status}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Default Templates</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.defaultTemplate == null || defaultTemplates.find(it => it.name == formData.defaultTemplate) == null ? "" : defaultTemplates.find(it => it.name == formData.defaultTemplate).name}>
                            {defaultTemplates.map((data) => {
                                return (
                                    <Dropdown.Item onClick={() => { formData.defaultTemplate = data.name }} key={data.name}>{data.name}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label-disabled">Asset Type</div>
                        <Form.Control className="modal-input-disabled" size="lg" type="text" placeholder={formData.assetType} aria-label="Disabled input example" readOnly />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label-disabled">Model</div>
                        <Form.Control className="modal-input-disabled" size="lg" type="text" placeholder={formData.model} aria-label="Disabled input example" readOnly />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label-disabled">Manufacturer</div>
                        <Form.Control className="modal-input-disabled" size="lg" type="text" placeholder={formData.manufacturer} aria-label="Disabled input example" readOnly />
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleClose} className="btn btn-light">Cancel</button>
                <button onClick={handleSubmit} className="btn btn-light">Submit</button>
                {title.includes("Create") ? <button onClick={handleSubmitPlus} className="btn btn-light">Submit +</button> : ""}
                
            </Modal.Footer>

            <Modal className="modalStyle" show={modalOpen} centered>
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>With the given manufacturer, product code and serial number already exists.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalErrorClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            </Modal>
        </>
    );
});

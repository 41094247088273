import React, {useEffect, useState} from "react";
import { useAuthFetch }  from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import { getApiUrl } from "./authConfig";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./assets/css/modal.css";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

export type CreateProductDialogData = {
    id?: string,
    description: string,
    assetType: string,
    se04Required: string,
    model: string,
    manufacturer: string,
    productCode: string,
    productFamily: string,
    defaultTemplate: string
}

const assetTypes = ["Water", "Gas", "Electricity", "Thermal", "Steam", "Communication"];

export type CreateProductDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateProductDialogData
    productFamilies?: Array<any>
    defaultTemplates?: Array<any>;
} 

export const CreateProductDialog = observer((props: CreateProductDialogProps)=> {
    const authFetch = useAuthFetch(); 
    const [ modalOpen, setModalOpen ] = useState(false); 

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;

    const productFamilies = props.productFamilies || [];
    const defaultTemplates = props.defaultTemplates || [];

    const handleModalErrorClose = () => { setModalOpen(false); };

    const initialData = props.initialData || {
        description: "", 
        assetType: "Water", 
        se04Required: "False", 
        model: "",
        manufacturer: "", 
        productCode: "", 
        productFamily: "",
        defaultTemplate: ""
    }

    const formData = useLocalObservable(()=> ({
        description: "", 
        assetType: "Water", 
        se04Required: "False", 
        model: "", 
        manufacturer: "",
        productCode: "", 
        productFamily: "",
        defaultTemplate: ""
    }))


    const setInitialData = ()=> {
        formData.description =  initialData.description;
        formData.assetType =  initialData.assetType;
        formData.se04Required =  initialData.se04Required; 
        formData.model =  initialData.model;
        formData.manufacturer =  initialData.manufacturer;
        formData.productCode = initialData.productCode;
        formData.productFamily = initialData.productFamily;
        formData.defaultTemplate = initialData.defaultTemplate;

        if(initialData.productFamily == "" && productFamilies.length != 0 ) {
            let fam = productFamilies[0]; 
            formData.productFamily = fam.id; 
            formData.assetType = fam.category;
        }

    }

    useEffect(()=> {setInitialData()}, [props.initialData]);

    const formError = useLocalObservable(()=> ({
        description: "",
        model: "",
        manufacturer: "", 
        productCode: "", 
        serialNumber: "",
        commSerialNumber: ""
    }))

    const clearErrors = ()=> {
        formError.description = ""; 
        formError.model = ""; 
        formError.manufacturer = ""; 
        formError.productCode = "";
        formError.serialNumber = "";
        formError.commSerialNumber = "";
    }

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async ()=> {

        let hasError = false;
        clearErrors();

        if(formData.description == "") {
            hasError = true; 
            formError.description = "Product description is required";  
        }

        if(formData.model == "") {
            hasError = true; 
            formError.model = "Product model is required";  
        }

        if(formData.manufacturer == "") {
            hasError = true; 
            formError.manufacturer = "Product manufacturer is required";  
        }

        if(formData.productCode == "") {
            hasError = true; 
            formError.productCode = "Product code is required";  
        }

        if(hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT"; 
        let url = getApiUrl("products"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, {...data})

        if(fetchResponse.status != 409) {
            if(props.onSubmit != null) {
                props.onSubmit("success")
            }
        } else {
            setModalOpen(true)
        }
    } 

    const title = existingId == ""? "Create Product": "Update Product";

    return (

        <Modal show={isOpen} size="lg">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={{ marginBottom: '12px' }}>Please enter the information about the product</div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Description</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.description} onChange={e => formData.description = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Product Family</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.productFamily == null || productFamilies.find(it => it.id == formData.productFamily) == null ? "" : productFamilies.find(it => it.id == formData.productFamily).name}>
                            {productFamilies.map((pFam) => {
                                return (
                                    <Dropdown.Item onClick={(data) => {
                                        if (data == null) {
                                            return;
                                        }
                                        formData.productFamily = pFam.id;
                                        formData.assetType = pFam.category;
                                    }}
                                        key={pFam.name}>{pFam.name}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Asset Type</div>
                        <DropdownButton disabled className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.assetType == null || assetTypes.find(it => it == formData.assetType) == null ? "" : assetTypes.find(it => it == formData.assetType)}>
                            {assetTypes.map((data) => {
                                return (
                                    <Dropdown.Item onClick={() => { formData.assetType = data}} key={data}>{data}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">SE04 Required</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.se04Required == null || ["True", "False"].find(it => it == formData.se04Required) == null ? "" : ["True", "False"].find(it => it == formData.se04Required)}>
                            {["True", "False"].map((data) => {
                                return (
                                    <Dropdown.Item onClick={() => { formData.se04Required = data }} key={data}>{data}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Model</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.model} onChange={e => formData.model = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Manufacturer</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.manufacturer} onChange={e => formData.manufacturer = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Product Code</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.productCode} onChange={e => formData.productCode = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Default Templates</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.defaultTemplate == null || defaultTemplates.find(it => it.name == formData.defaultTemplate) == null ? "" : defaultTemplates.find(it => it.name == formData.defaultTemplate).name}>
                            {defaultTemplates.map((data) => {
                                return (
                                    <Dropdown.Item onClick={() => { formData.defaultTemplate = data.name }} key={data.name}>{data.name}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button onClick={handleClose} className="btn btn-light">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} className="btn btn-light">
                    Submit
                </Button>
            </Modal.Footer>

            <Modal className="modalStyle" show={modalOpen} centered>
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>The machine with the given manufacturer, product code and serial number already exists.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalErrorClose}>Close</Button>
                </Modal.Footer>
            </Modal>

        </Modal>
    );
});

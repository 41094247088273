import React, {useEffect, useState} from "react";
import { useAuthFetch }  from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import { getApiUrl } from "./authConfig";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./assets/css/modal.css";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

export type CreateProductFamilyDialogData = {
    id?: string,
    name: string,
    category: string,
    manufacturer: string,
    defaultTemplate: string
}

export type CreateProductDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateProductFamilyDialogData
    defaultTemplates?: Array<any>;
} 

export const CreateProductFamilyDialog = observer((props: CreateProductDialogProps)=> {
    const authFetch = useAuthFetch(); 
    const [ modalOpen, setModalOpen ] = useState(false); 

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;

    const defaultTemplates = props.defaultTemplates || [];

    const handleModalErrorClose = () => { setModalOpen(false); };

    const initialData = props.initialData || {
        name: "", 
        category: "", 
        manufacturer: "", 
        defaultTemplate: ""
    }

    const formData = useLocalObservable(()=> ({
        name: "",
        category: "",
        manufacturer: "",
        defaultTemplate: ""
    }))


    const setInitialData = ()=> {
        formData.name =  initialData.name;
        formData.category = initialData.category;
        formData.manufacturer = initialData.manufacturer; 
        formData.defaultTemplate = initialData.defaultTemplate;
    }

    useEffect(()=> {setInitialData()}, [props.initialData]);

    const formError = useLocalObservable(()=> ({
        name: "",
        category: "",
        manufacturer: "",
        defaulttemplate: ""
    }))

    const clearErrors = ()=> {
        formError.name = ""; 
        formError.category = ""; 
        formError.manufacturer = ""; 
        formError.defaulttemplate = "";
    }

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async ()=> {

        let hasError = false;
        clearErrors();

        if(formData.name == "") {
            hasError = true; 
            formError.name = "Product Family name is required";  
        }

        if(formData.category == "") {
            hasError = true; 
            formError.category = "Product Family category is required";  
        }

        if(formData.manufacturer == "") {
            hasError = true; 
            formError.manufacturer = "Product manufacturer is required";  
        }

        if(hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT"; 
        let url = getApiUrl("products/families"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, {...data})

        if(fetchResponse.status != 409) {
            if(props.onSubmit != null) {
                props.onSubmit("success")
            }
        } else {
            setModalOpen(true)
        }
    } 

    const title = existingId == ""? "Create Product Family": "Update Product Family";

    return (

        <Modal show={isOpen} size="lg">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={{ marginBottom: '12px' }}>Please enter the information about the product family</div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Name</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.name} onChange={e => formData.name = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Category</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.category} onChange={e => formData.category = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Manufacturer</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.manufacturer} onChange={e => formData.manufacturer = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Default Templates</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.defaultTemplate == null || defaultTemplates.find(it => it.name == formData.defaultTemplate) == null ? "" : defaultTemplates.find(it => it.name == formData.defaultTemplate).name}>
                            {defaultTemplates.map((data) => {
                                return (
                                    <Dropdown.Item onClick={() => { formData.defaultTemplate = data.name }} key={data.name}>{data.name}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>
                
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} className="btn btn-light">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} className="btn btn-light">
                    Submit
                </Button>
            </Modal.Footer>

            <Modal className="modalStyle" show={modalOpen} centered>
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>The machine with the given manufacturer, product code and serial number already exists.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalErrorClose}>Close</Button>
                </Modal.Footer>
            </Modal>

        </Modal>
    );
});

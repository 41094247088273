import React, { useState, useEffect, useRef } from 'react'
import { useFetcher, useParams } from 'react-router-dom';
import { useAuthFetch } from '../useAuthFetch';
import { getApiUrl } from '../authConfig';
import moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas-pro';
import { useMsal } from '@azure/msal-react';

import "../assets/css/roiPage.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { RoiObject } from "../common/types";
import { AssetObject } from "../common/types";

import { getROIData } from "../hooks/Roi";
import { getROISnapShotData } from "../hooks/Roi";
import { getROIByIdData } from "../hooks/Roi";

import { LoadingTemplate } from '../LoadingTemplate';

export function ROIViewPage() {
    const { accounts } = useMsal();
    const params = useParams();
    const authFetch = useAuthFetch();
    const inputRef = useRef(null);

    const assetId = params.assetId;
    const [loading, setLoading] = useState(false);

    const [roiData, setRoiData] = useState<RoiObject>(null);
    const [roiVersionList, setRoiVersionList] = useState<RoiObject[]>([]);

    function comminssioningNullCheck(commissioningData, index, checkCtInfo) {
        if (commissioningData == null || commissioningData.mpInfo[index] == null) {
            return true;
        } else if (checkCtInfo && commissioningData.mpInfo[index].ctInfo == null) {
            return true
        } else {
            return false
        }
    }

    function formatAddress(commissioningData, index) {
        if (commissioningData == null || commissioningData.mpInfo[index] == null) {
            return "";
        } else if (commissioningData.mpInfo[index].unitNumber == null) {
            return "";
        } else {
            return commissioningData.mpInfo[index].unitNumber + " - " + commissioningData.mpInfo[index].unitName + " - " + commissioningData.mpInfo[index].serviceAddress
        }
    }

    const pdfExport = (roiID: string) => {

        html2canvas(inputRef.current).then((canvas) => {

            const image = { type: 'jpeg', quality: 0.98 };
            const margin = [0.5, 0.5];

            var imgWidth = 11;
            var pageHeight = 8.5;

            var innerPageWidth = imgWidth - margin[0] * 2;
            var innerPageHeight = pageHeight - margin[1] * 2;

            // Calculate the number of pages.
            var pxFullHeight = canvas.height;
            var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
            var nPages = Math.ceil(pxFullHeight / pxPageHeight);

            // Define pageHeight separately so it can be trimmed on the final page.
            var pageHeight = innerPageHeight;

            // Create a one-page canvas to split up the full image.
            var pageCanvas = document.createElement('canvas');
            var pageCtx = pageCanvas.getContext('2d');
            pageCanvas.width = canvas.width;
            pageCanvas.height = pxPageHeight;

            var pdf = new jsPDF('l', 'in', [11, 8.5]);

            for (var page = 0; page < nPages; page++) {
                // Trim the final page to reduce file size.
                if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
                    pageCanvas.height = pxFullHeight % pxPageHeight;
                    pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
                }

                // Display the page.
                var w = pageCanvas.width;
                var h = pageCanvas.height;
                pageCtx.fillStyle = 'white';
                pageCtx.fillRect(0, 0, w, h);
                pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

                // Add the page to the PDF.
                if (page > 0) pdf.addPage();

                var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
                pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
            }

            pdf.save("roi-" + roiID + ".pdf");
        });
    };

    function qaStatusDateCheck(item: string) {
        let checkOK = true;

        let fieldCommissioningDate = moment(roiData?.inspection.completionDate);
        let techReviewDate = moment(roiData?.asset.techReview.completionDate);
        let qaReviewDate = moment(roiData?.asset.qaReview.completionDate);
        let qaApprovalDate = moment(roiData?.asset.qaApproval.completionDate);

        if (item === "qaApproval") {
            if (qaApprovalDate.isBefore(qaReviewDate)) {
                checkOK = false;
            }
            if (qaReviewDate.isBefore(techReviewDate)) {
                checkOK = false;
            }
            if (techReviewDate.isBefore(fieldCommissioningDate)) {
                checkOK = false;
            }
        }

        if (item === "qaReview") {
            if (qaReviewDate.isBefore(techReviewDate)) {
                checkOK = false;
            }
            if (techReviewDate.isBefore(fieldCommissioningDate)) {
                checkOK = false;
            }
        }

        if (item === "techReview") {
            if (techReviewDate.isBefore(fieldCommissioningDate)) {
                checkOK = false;
            }
        }

        return checkOK;
    };

    const roiSignButton = (item: string) => {
        let isOK = qaStatusDateCheck(item);
        if (isOK) {
            return (
                <>
                    <Button style={{marginRight: "4px"}} type="button" variant="secondary" onClick={() => { cellClickEvent(item, 1); }} className="btn btn-primary btn-sm">Pass</Button>
                    <Button type="button" variant="secondary" onClick={() => { cellClickEvent(item, 2); }} className="btn btn-primary btn-sm">Fail</Button> 
                </>
            );
        } else {
            return (
                <>
                    <Button style={{ marginRight: "4px" }} type="button" variant="danger" onClick={() => { cellClickEvent(item, 1); }} className="btn btn-primary btn-sm">Pass</Button>
                    <Button type="button" variant="danger" onClick={() => { cellClickEvent(item, 2); }} className="btn btn-primary btn-sm">Fail</Button>
                </>

            );
        }
    };

    function qaStatusMapping(status: number) {
        let returnValue = "";
        if (status == 0) {
            returnValue = "Incomplete";
        } else if (status == 1) {
            returnValue = "Passed";
        } else if (status == 2) {
            returnValue = "Failed";
        }
        return returnValue;
    }

    useEffect(() => {
        getROISnapShotData(authFetch, assetId).then(res => { return res; }).then((data) => { setRoiData(data);});

        getROIData(authFetch, assetId).then(res => { return res; }).then((data) => {
            setRoiVersionList(data);
        })

    }, []);

    const setROISelect = async (roiId: string) => {
        if (roiId === "0") {
            getROISnapShotData(authFetch, assetId).then(res => { return res; }).then((data) => { setRoiData(data);});
        } else {
            getROIByIdData(authFetch, roiId).then(res => { return res; }).then((data) => { setRoiData(data);});
        }
    }

    const cellClickEvent = async (item, status) => {
        let newRoiData = JSON.parse(JSON.stringify(roiData));
        const date = new Date();

        const jsonArray = '{"qaStatus": "' + status + '", "completionDate": "' + date.toISOString() + '", "updatedBy": "' + accounts[0]?.name + '"}'
        if (item === "techReview") {
            newRoiData.asset.techReview = JSON.parse(jsonArray);
        } else if (item === "qaReview") {
            newRoiData.asset.qaReview = JSON.parse(jsonArray);
        } else if (item === "qaApproval") {
            newRoiData.asset.qaApproval = JSON.parse(jsonArray);
        }

        let url = getApiUrl("assets");
        url = `${url}/${roiData.asset.id}`;

        let fetchResponse = await authFetch("PUT", url, { ...newRoiData.asset, buildingId: roiData.asset.buildingId })
        if (fetchResponse.status != 409) {
            //console.log("UPDATED!");
        } else {
            console.log("ERROR");
        }

        setRoiData(newRoiData);

        if (item === "qaApproval") {
            let urlROI = getApiUrl("rois");
            urlROI = `${urlROI}?assetId=${roiData.asset.id}`;

            let fetchResponse = await authFetch("POST", urlROI, { ...newRoiData.asset })
            if (fetchResponse.status != 409) {
                //console.log("UPDATED!");
            } else {
                console.log("ERROR");
            }
        }
    }

    function questionResponse(roiInfo: RoiObject, question: string) {
        if (roiInfo == null) {
            return "";
        } else if (roiInfo?.inspection == null) {
            return "";
        } else if (roiInfo.inspection.answers.find(x => x.questionId === question) == null) {
            return "";
        } else {
            return roiInfo.inspection.answers.find(x => x.questionId === question).response;
        }
    }

    return (
        <LoadingTemplate isLoading={loading}>
            {roiData != null && roiData?.sealingInfo != null ?
                <div style={{ width: "18%", height: "140px", float: "left", padding: "10px" }}>
                <div className="dropdown-label">ROI Version</div>
                <DropdownButton variant="outline-secondary" className="page-dropdown" id="dropdown-basic" title={roiData?.creationDate == "0001-01-01T00:00:00" ? "Current SnapShot" : moment(roiData.creationDate).format('MMM DD YYYY, h:mm A')}>
                        <Dropdown.Item onClick={() => { setROISelect("0"); }}>Current SnapShot</Dropdown.Item>
                    {roiVersionList.map((item) => (
                        <Dropdown.Item key={item.id} onClick={() => { setROISelect(item.id); }}>
                            {moment(item.creationDate).format('MMM DD YYYY, h:mm A')}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </div>
            :
            ""
            }
            <div>
                {roiData == null || roiData.sealingInfo == null ? <div style={{ color: "lightgray", fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>No ROI Data</div> :

                    <>
                        <div style={{ width: "100%", display: "table" }}>
                            <div style={{ width: "12%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%", textAlign: "center" }}>&nbsp;</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}><div style={{ fontWeight: "bold", float: "left", width: "48%" }}>&nbsp;</div><div style={{ float: "left" }}></div></div>
                                    <div style={{ fontWeight: "bold", width: "100%" }}>Signed By:</div>
                                    <div style={{ fontWeight: "bold", width: "100%" }}>Date:</div>
                                    <div style={{ fontWeight: "bold", width: "100%" }}>Review Status:</div>
                                </div>
                            </div>

                            <div style={{ width: "18%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%" }}>FIELD COMMISSIONING</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}></div>
                                    <div style={{ width: "100%" }}>{roiData?.inspection.creatorName}</div>
                                    <div style={{ width: "100%" }}>{roiData?.inspection == null ? "" : moment(roiData.inspection.completionDate).format('MMM DD YYYY, h:mm A')}</div>
                                    <div style={{ width: "100%" }}>&nbsp;</div>
                                </div>
                            </div>
                            <div style={{ borderLeft: "1px solid lightgrey", float: "left", width: "1px", height: "110px", marginTop: "15px" }}></div>
                            <div style={{ width: "18%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%" }}>SENIOR TECH REVIEW</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}>{roiSignButton("techReview")}</div>
                                    <div style={{ width: "100%" }}>{roiData?.asset == null || roiData.asset.techReview == null ? "" : roiData.asset.techReview.updatedBy}</div>
                                    {roiData?.asset != null && qaStatusDateCheck("techReview") ?
                                        <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : moment(roiData.asset.techReview.completionDate).format('MMM DD YYYY, h:mm A')}</div>
                                        :
                                        <div style={{ width: "100%", color: "red", fontWeight: "bold" }}>{roiData?.asset == null ? "" : moment(roiData.asset.techReview.completionDate).format('MMM DD YYYY, h:mm A')}</div>
                                    }
                                    <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : qaStatusMapping(roiData.asset.techReview.qaStatus)}</div>
                                </div>
                            </div>
                            <div style={{ borderLeft: "1px solid lightgrey", float: "left", width: "1px", height: "110px", marginTop: "15px" }}></div>
                            <div style={{ width: "18%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%" }}>QA CONTROLLER REVIEW</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}>
                                        {roiData?.asset != null && qaStatusDateCheck("techReview") ? roiSignButton("qaReview") : "" }
                                    </div>
                                    <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : roiData.asset.qaReview.updatedBy}</div>
                                    {roiData?.asset != null && qaStatusDateCheck("qaReview") ?
                                        <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : moment(roiData.asset.qaReview.completionDate).format('MMM DD YYYY, h:mm A')}</div>
                                        :
                                        <div style={{ width: "100%", color: "red", fontWeight: "bold" }}>{roiData?.asset == null ? "" : moment(roiData.asset.qaReview.completionDate).format('MMM DD YYYY, h:mm A')}</div>
                                    }
                                    <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : qaStatusMapping(roiData.asset.qaReview.qaStatus)}</div>
                                </div>
                            </div>
                            <div style={{ borderLeft: "1px solid lightgrey", float: "left", width: "1px", height: "110px", marginTop: "15px" }}></div>
                            <div style={{ width: "18%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%" }}>QA MANAGER REVIEW</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}>
                                        {roiData?.asset != null && qaStatusDateCheck("qaReview") ? roiSignButton("qaApproval") : "" }
                                    </div>
                                    <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : roiData.asset.qaApproval.updatedBy}</div>
                                    {roiData?.asset != null && qaStatusDateCheck("qaApproval") ?
                                        <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : moment(roiData?.asset.qaApproval.completionDate).format('MMM DD YYYY, h:mm A')}</div>
                                        :
                                        <div style={{ width: "100%", color: "red", fontWeight: "bold" }}>{roiData?.asset == null ? "" : moment(roiData.asset.qaApproval.completionDate).format('MMM DD YYYY, h:mm A')}</div>
                                    }
                                    <div style={{ width: "100%" }}>{qaStatusMapping(roiData?.asset.qaApproval.qaStatus)}</div>
                                </div>
                            </div>
                        </div>

                        <Button type="button" className="btn btn-primary btn-sm" style={{ float: "right", marginRight: "5px", marginTop: "5px" }} onClick={() => pdfExport(roiData.asset?.serialNumber)}>ROI TO PDF&nbsp;&nbsp;<i className="fa fa-download" /></Button>
                        <div className="row-container" id="roi-container" ref={inputRef}>
                            <div style={{ display: "ruby" }}>
                                <div className="roi-header">
                                    <img src={require("../assets/images/logo.png")} className="roi-header-logo" />
                                    <div className="roi-header-text">Record of Meter Installation<br /><span style={{ color: "#FF5733" }}>{roiData?.asset?.manufacturer == null ? "" : roiData.asset?.manufacturer} {roiData?.asset?.model == null ? "" : roiData.asset?.model} Meter</span></div>
                                </div>

                                <div className="roi-body">
                                    <div className="roi-body-header">
                                        <div className="roi-body-header-text">General Info</div>
                                    </div>
                                    <div className="roi-body-container-half">
                                        <div className="roi-body-container-label">Client:</div>
                                        <div className="roi-body-container-value">{roiData?.sealingInfo == null || roiData.sealingInfo?.clientName == null ? <div>&nbsp;</div> : roiData.sealingInfo?.clientName}</div>
                                        <div className="roi-body-container-label">Address:</div>
                                        <div className="roi-body-container-value">{roiData?.asset?.building == null ? "" : roiData.asset?.building.address + " " + roiData.asset?.building.city + ", " + roiData.asset?.building.province}</div>
                                        <div className="roi-body-container-label">QMC Reference:</div>
                                        <div className="roi-body-container-value">{roiData?.sealingInfo == null || roiData.sealingInfo?.workOrder == null ? "" : roiData.sealingInfo?.workOrder}</div>
                                        <div className="roi-body-container-label">Contractor No.</div>
                                        <div className="roi-body-container-value">{roiData?.sealingInfo == null || roiData.sealingInfo?.contractorNumber == null ? "" : roiData.sealingInfo?.contractorNumber}</div>
                                    </div>
                                    <div className="roi-body-container-half roi-body-container-right">
                                        <div className="roi-body-container-data-half">
                                            <div className="roi-body-container-label">Serial No.</div>
                                            <div className="roi-body-container-value">{roiData?.asset == null || roiData.asset?.serialNumber == null ? "" : roiData.asset?.serialNumber}</div>
                                            <div className="roi-body-container-label">Inspection No.</div>
                                            <div className="roi-body-container-value">{roiData?.sealingInfo == null || roiData.sealingInfo?.inspectionNumber == null ? "" : roiData.sealingInfo?.inspectionNumber}</div>
                                            <div className="roi-body-container-label">Installation Date:</div>
                                            <div className="roi-body-container-value">{questionResponse(roiData, "Installation_Date")}</div>
                                            <div className="roi-body-container-label">Meter Location:</div>
                                            <div className="roi-body-container-value">{questionResponse(roiData, "Physical_Location")}</div>
                                        </div>

                                        <div className="roi-body-container-data-half">
                                            <div className="roi-body-container-label">Approval No.</div>
                                            <div className="roi-body-container-value">{questionResponse(roiData, "MC_Approval_Number")}</div>
                                            <div className="roi-body-container-label">Installer Name:</div>
                                            <div className="roi-body-container-value">{questionResponse(roiData, "Installer_Name")}</div>
                                            <div className="roi-body-container-label">Sealing Year:</div>
                                            <div className="roi-body-container-value">{roiData?.sealingInfo == null || roiData.sealingInfo?.sealDate == null ? "" : moment(roiData.sealingInfo?.sealDate).format('YYYY')}</div>
                                        </div>
                                    </div>

                                    <div className="roi-body-header">
                                        <div className="roi-body-header-text">Sealing Parameters</div>
                                    </div>
                                    <div className="roi-body-container-half">
                                        <div className="roi-body-container-label">Sealing Parameters:</div>
                                        <div className="roi-body-container-value">{roiData?.sealingInfo == null || roiData.sealingInfo?.sealParameters == null ? "" : roiData.sealingInfo?.sealParameters.map(txt => <span key={txt}>{txt}, </span>)}</div>
                                        <div className="roi-body-container-spacer">&nbsp;</div>
                                    </div>
                                    <div className="roi-body-container-half roi-body-container-right">
                                        <div className="roi-body-container-data-half">
                                            <div className="roi-body-container-label">Meter Configuration</div>
                                            <div className="roi-body-container-value">{roiData?.sealingInfo == null || roiData.sealingInfo?.meterConfiguration == null ? "" : roiData.sealingInfo?.meterConfiguration}</div>
                                            <div className="roi-body-container-label">CT Configuration:</div>
                                            <div className="roi-body-container-value">{roiData?.sealingInfo == null || roiData.sealingInfo?.ctConfiguration == null ? "" : roiData.sealingInfo?.ctConfiguration}</div>
                                        </div>
                                        <div className="roi-body-container-data-half">
                                            <div className="roi-body-container-label">Number Elements</div>
                                            <div className="roi-body-container-value">{roiData?.sealingInfo == null || roiData.sealingInfo?.elements == null ? "" : roiData.sealingInfo?.elements}</div>
                                        </div>
                                    </div>

                                    <div className="roi-body-header">
                                        <div className="roi-body-header-text">Reference Voltage</div>
                                    </div>
                                    <div className="roi-body-container-table-half">
                                        <Table className="table table-sm" style={{ textAlign: "center" }}>
                                            <thead>
                                                <tr style={{ borderBottom: "2px solid" }}>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Voltage 1</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Prim.</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Sec.</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>PT Serial</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Ratio</th>
                                                    <th>Volts</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>Phase A</td>
                                                    <td style={{ verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{questionResponse(roiData, "REF_Voltage")}</td>
                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                    <td style={{ verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }} rowSpan={3} className="value-orange">{roiData?.sealingInfo == null || roiData.sealingInfo?.ptSerialNumber == null ? "" : roiData.sealingInfo?.ptSerialNumber}</td>
                                                    <td style={{ verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{questionResponse(roiData, "PT_Ratio")}</td>
                                                    <td>{questionResponse(roiData, "REF_PhaseA_Voltage")}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>Phase B</td>
                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                    <td>{questionResponse(roiData, "REF_PhaseB_Voltage")}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>Phase C</td>
                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                    <td>{questionResponse(roiData, "REF_PhaseC_Voltage")}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="roi-body-container-table-half roi-body-container-right" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {
                                            roiData?.inspection == null || roiData.inspection.answers.find(x => x.questionId === "REF_PhaseA_Voltage2") == null ? <div style={{ color: "lightgray", fontSize: '2rem', fontWeight: 'bold' }}>No Ref Voltage 2 Data</div> :

                                                <Table className="table table-sm" style={{ marginTop: '15px;', textAlign: "center" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Voltage 2</th>
                                                            <th>Prim.</th>
                                                            <th>Sec.</th>
                                                            <th>PT Serial</th>
                                                            <th>Ratio</th>
                                                            <th>Volts</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Phase A</td>
                                                            <td className="value-orange">REF_Voltage</td>
                                                            <td></td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }} rowSpan={3} className="value-orange">PT_SN_Ref_2</td>
                                                            <td className="value-orange">PT_Ratio_Ref2</td>
                                                            <td className="value-green">REF_PhaseA_Voltage</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phase B</td>
                                                            <td className="value-orange">REF_Voltage</td>
                                                            <td></td>
                                                            <td className="value-orange">PT_Ratio_Ref2</td>
                                                            <td className="value-green">REF_PhaseB_Voltage</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phase C</td>
                                                            <td className="value-orange">REF_Voltage</td>
                                                            <td></td>
                                                            <td className="value-orange">PT_Ratio_Ref2</td>
                                                            <td className="value-green">REF_PhaseC_Voltage</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>}
                                    </div>

                                    <div className="roi-body-header">
                                        <div className="roi-body-header-text">Meter Point Information</div>
                                    </div>
                                    <div className="roi-body-container-table-full">
                                        <Table className="table table-sm" style={{ borderCollapse: 'collapse', textAlign: "center" }} >
                                            <thead>
                                                <tr style={{ borderBottom: "2px solid" }}>
                                                    <th style={{ textAlign: 'center', borderRight: "solid 1px #E0E0E0" }}>Meter<br />Point</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>MP-ID</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Vref</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Phase</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Amps</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Watts</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Phase<br />Angle</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Power<br />Factor</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>CT Model</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>CT Serial No.</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>CT Ratio</th>
                                                    <th style={{ borderRight: "solid 1px #E0E0E0" }}>Accepted<br />(Yes/No)</th>
                                                    <th style={{ textAlign: 'center', borderRight: "solid 1px #E0E0E0" }}>Panel / Desciption</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {[...Array(roiData?.sealingInfo?.numMeteringPoints)].map((x, i) => (
                                                    <React.Fragment key = {i}>
                                                        <tr>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{i + 1}</td>
                                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>MP{i + 1}-1</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{comminssioningNullCheck(roiData?.commissioningData, i, false) ? "" : roiData.commissioningData.mpInfo[i].referenceVoltageNum}</td>
                                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>A</td>
                                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].amperage}</td>
                                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].power}</td>
                                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].phaseAngle}</td>
                                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].powerFactor}</td>
                                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, false) ? "" : roiData.commissioningData.mpInfo[i].ctInfo == null ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].sn}</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{comminssioningNullCheck(roiData?.commissioningData, i, false) ? "" : roiData.commissioningData.mpInfo[i].ctRatio}</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{comminssioningNullCheck(roiData?.commissioningData, i, false) ? "" : roiData.commissioningData.mpInfo[i].commissioning}</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{formatAddress(roiData?.commissioningData, i)}</td>
                                                        </tr>
                                                        {
                                                            !comminssioningNullCheck(roiData?.commissioningData, i, true) && roiData.commissioningData.mpInfo[i].ctInfo[1] != null ?
                                                                <tr>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>MP{i + 1}-2</td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>B</td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].amperage}</td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].power}</td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].phaseAngle}</td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].powerFactor}</td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].sn}</td>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>MP{i + 1}-2</td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}>B</td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                                                </tr>
                                                        }

                                                        {
                                                            !comminssioningNullCheck(roiData?.commissioningData, i, true) && roiData.commissioningData.mpInfo[i].ctInfo[2] != null ?
                                                                <tr>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>MP{i + 1}-3</td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>C</td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].amperage}</td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].power}</td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].phaseAngle}</td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].powerFactor}</td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].sn}</td>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>MP{i + 1}-3</td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>C</td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}></td>
                                                                    <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}></td>
                                                                </tr>
                                                        }

                                                    </React.Fragment>
                                                )
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>

                                    <div className="roi-body-header">
                                        <div className="roi-body-header-text">Communications</div>
                                    </div>
                                    <div className="roi-body-container-full">

                                        {roiData?.inspection == null || roiData.inspection.answers.find(x => x.questionId === "Communication_Ethernet") == null ? "" :
                                            <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                                                <div style={{ width: "100%", height: "20px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }}>Ethernet</div>
                                                <div style={{ padding: "4px", width: "100%" }}>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>MAC:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "MAC_Address")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>IP:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Ethernet_IP")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Subnet:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Ethernet_Subnet_Mask")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Gateway:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Ethernet_Gateway_IP")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>DNS:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Ethernet_DNS")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Time Server:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Ethernet_Time_Server")}</div></div>
                                                </div>
                                            </div>
                                        }

                                        {roiData?.inspection == null || (roiData.inspection.answers.find(x => x.questionId === "Communication_FTP") == null && roiData.inspection.answers.find(x => x.questionId === "Communication_Https")) == null ? "" :
                                            <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                                                <div style={{ width: "100%", height: "20px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }}>FTP / HTTPS</div>
                                                <div style={{ padding: "4px", width: "100%" }}>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>FTP Server:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "FTP_Path")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Port:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "FTP_Port")}</div></div>
                                                    <div style={{ width: "100%" }}>&nbsp;</div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>HTTPS:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "HTTPs_Enabled")}</div></div>
                                                </div>
                                            </div>
                                        }

                                        {roiData?.inspection == null || roiData.inspection.answers.find(x => x.questionId === "Communication_Bacnet_MS_TP") == null ? "" :
                                            <div style={{ width: "27%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                                                <div style={{ width: "100%", height: "20px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }}>BACnet MSTP</div>
                                                <div style={{ padding: "4px", width: "100%" }}>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>BACnet ID:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "BacnetMSTP_Device_ID")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Object ID:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "BacnetMSTP_Object_ID")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Baudrate:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "BacnetMSTP_Baud Rate")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Parity:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Bacnet_Parity")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Stop Bit:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Bacnet_Stop_Bit")}</div></div>
                                                </div>
                                            </div>
                                        }

                                        {roiData?.inspection == null || roiData.inspection.answers.find(x => x.questionId === "Communication_Modbus_RTU") == null ? "" :
                                            <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                                                <div style={{ width: "100%", height: "20px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }}>Modbus RTU</div>
                                                <div style={{ padding: "4px", width: "100%" }}>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Modbus ID:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "ModbusRTU_ID")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Bit:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Modbus_Bit")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Baudrate:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Modbus_Baud_Rate")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Parity:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Modbus_Parity")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Stop Bit:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "Modbus_Stop_Bit")}</div></div>
                                                </div>
                                            </div>
                                        }

                                        {roiData?.inspection == null || (roiData.inspection.answers.find(x => x.questionId === "Communication_Modbus_TCP") == null && roiData.inspection.answers.find(x => x.questionId === "Communication_Bacnet_IP")) == null ? "" :
                                            <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                                                <div style={{ width: "100%", height: "20px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }}>Modbus TCP/BACnet IP</div>
                                                <div style={{ padding: "4px", width: "100%" }}>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>BACnet ID:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "BacnetIP_Device_ID")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>BACnet Port:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "BacnetIP_Port")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Object ID:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "BacnetIP_Object_ID")}</div></div>
                                                    <div style={{ width: "100%" }}>&nbsp;</div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Modbus ID:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "ModbusTCP_ID")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Modbus Port:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "ModbusTCP_Port")}</div></div>
                                                </div>
                                            </div>
                                        }

                                        {roiData?.inspection == null || (roiData.inspection.answers.find(x => x.questionId === "Communication_CellModem") == null && roiData.inspection.answers.find(x => x.questionId === "Communication_LoRaWAN")) == null ? "" :
                                            <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                                                <div style={{ width: "100%", height: "20px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }}>Wireless Option</div>
                                                <div style={{ padding: "4px", width: "100%" }}>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>BACnet ID:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "BacnetIP_Device_ID")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>BACnet Port:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "BacnetIP_Port")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Object ID:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "BacnetIP_Object_ID")}</div></div>
                                                    <div style={{ width: "100%" }}>&nbsp;</div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Modbus ID:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "ModbusTCP_ID")}</div></div>
                                                    <div style={{ width: "100%", position: "relative", overflow: "hidden" }}><div style={{ fontWeight: "bold", float: "left", marginRight: "4px" }}>Modbus Port:</div><div style={{ float: "left", position: "absolute", display: "inline-block" }}>{questionResponse(roiData, "ModbusTCP_Port")}</div></div>
                                                </div>
                                            </div>
                                        }

                                        <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left", display: "none" }}>
                                            <div style={{ width: "100%", height: "20px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }}>Others</div>
                                            <div style={{ padding: "4px", width: "100%" }}>
                                                <div style={{ width: "100%" }}>&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="roi-body-header">
                                        <div className="roi-body-header-text">Notes</div>
                                    </div>
                                    <div className="roi-body-container-full" style={{ padding: "5px" }}>
                                        {roiData?.notes == null ? "" : roiData.notes.map(value => (<div key={value}>{value}</div>))}
                                    </div>

                                    <div className="roi-body-header">
                                        <div className="roi-body-header-text">Sealing Info</div>
                                    </div>
                                    <div className="roi-body-container-half-sealing">
                                        <div className="roi-body-container-spacer">&nbsp;</div>
                                        <div className="roi-body-container-label">Inspection Status:</div>
                                        <div className="roi-body-container-value">&nbsp;</div>
                                        <div className="roi-body-container-label-sealing">Installation Tested and Verified:</div>
                                        <div className="roi-body-container-value"><Form.Check id="install1" type="checkbox" checked={roiData?.asset == null || roiData.asset?.status == "Pass" ? true : false} /></div>
                                        <div className="roi-body-container-label-sealing">Installation Tested, Corrected and Verified (see Notes):</div>
                                        <div className="roi-body-container-value"><Form.Check id="install2" type="checkbox" checked={ false} /></div>{/* This field will have no value to keep the original Report Structure */}
                                        <div className="roi-body-container-label-sealing">Installation Rejected:</div>
                                        <div className="roi-body-container-value"><Form.Check id="install3" type="checkbox" label="" checked={roiData?.asset == null || roiData.asset?.status == "Fail" ? true : false} /></div>
                                        <div className="roi-body-container-spacer">&nbsp;</div>
                                        <div className="roi-body-container-label-short">QMC Seal:</div>
                                        <div className="roi-body-container-value"><div className="roi-body-container-signed">{questionResponse(roiData, "Seal_Number")}</div>(Red seal)</div>
                                        <div className="roi-body-container-spacer">&nbsp;</div>
                                    </div>
                                    <div className="roi-body-container-half-sealing roi-body-container-right">
                                        <div className="roi-body-container-spacer">&nbsp;</div>
                                        <div className="roi-body-container-label-longer">Commissioned Date:</div>
                                        <div className="roi-body-container-value">{roiData?.inspection == null ? "" : moment(roiData?.inspection.completionDate).format('MM/d/YYYY')}</div>
                                        <div className="roi-body-container-label-longer">Commissioned By:</div>
                                        <div className="roi-body-container-value"><div className="roi-body-container-signed">{roiData?.inspection == null ? "" : roiData.inspection.creatorName}</div>Any tech</div>
                                        <div className="roi-body-container-spacer">&nbsp;</div>
                                        <div className="roi-body-container-label-longer">S-E-04 Inspection Date:</div>
                                        <div className="roi-body-container-value"><div className="roi-body-container-signed">{roiData?.asset == null || moment(roiData.asset?.techReview.completionDate).format('MM/d/YYYY')}</div></div>
                                        <div className="roi-body-container-label-longer">S-E-04 Inspection By:</div>
                                        <div className="roi-body-container-value"><div className="roi-body-container-signed">{roiData?.asset == null || roiData.asset?.techReview.updatedBy == "" ? <span>&nbsp;</span> : roiData.asset?.techReview.updatedBy}</div>Senior Techinician</div>
                                        <div className="roi-body-container-spacer">&nbsp;</div>

                                        <div id="roi-signature-container">
                                            <div className="roi-body-container-label-longer">Signed By:</div>
                                            <div className="roi-body-container-label-sealing" style={{ fontFamily: "Parisienne, cursive", fontWeight: "600", fontStyle: "normal", fontSize: "22px" }}>
                                                {roiData?.inspection.creatorName}
                                            </div>
                                            <div className="roi-body-container-label-longer">&nbsp;</div>
                                            <div className="roi-body-container-label-sealing" style={{ fontWeight: "600" }} >{moment(roiData?.inspection.creationDate).format('MMM DD YYYY, h:mm A')}</div>
                                        </div>

                                        <div className="roi-body-container-spacer">&nbsp;</div>
                                        <div className="roi-body-container-spacer">&nbsp;</div>
                                    </div>

                                </div>
                        </div>
                    </div>
                </>
                }
            </div>
        </LoadingTemplate>
    );
};


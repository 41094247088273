import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from "react"
import { createRoot } from "react-dom/client";
import $ from 'jquery';


import { App } from "./App";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';


import { configure as mobxConfigure } from "mobx";

mobxConfigure({
    enforceActions: "never",
});

import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise"

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-066845}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Quadlogic_Meters_Canada_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{MosaiQ}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{MosaiQ}_need_to_be_licensed___{MosaiQ}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_September_2025}____[v3]_[01]_MTc1NzU0NTIwMDAwMA==a3fa70a3a2a9af13e38df02f6d7965e0')


const container = document.getElementById("app");
const root = createRoot(container)

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <React.StrictMode>
        {/*<StyledEngineProvider injectFirst>*/}
            {/*<CssBaseline/>*/}
            <MsalProvider instance={msalInstance}>
                <App/>
            </MsalProvider>
        {/*</StyledEngineProvider>*/}
    </React.StrictMode>
);
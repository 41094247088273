import React, { useCallback, useEffect, useRef, useMemo, Component } from 'react';
import { useState } from "react";
import { useAuthFetch } from '../useAuthFetch';
import { LoadingTemplate } from '../LoadingTemplate';
import { getApiUrl } from '../authConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateAssetDialog } from '../CreateAssetDialog';
import { InspectionPreview } from '../InspectionPreview';
import moment from 'moment';

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from 'react-bootstrap/Button';
import "../assets/css/formItems.css";

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {
    ColDef,
    RowSelectionOptions,
    SideBarDef,
    ValueFormatterParams,
    IRichCellEditorParams,
} from "ag-grid-community";

import { requestAssetDataInspectionlatest } from "../hooks/Asset";
import { requestBuildingData } from "../hooks/Building";
import { requestProductFamilyData } from "../hooks/ProductFamily";
import { requestProductData } from "../hooks/Product";
import { requestLastInspectionData } from "../hooks/Inspection";
import { requestTemplateCheckedData } from "../hooks/Template";
import { createPDF } from "../hooks/CreatePDF";

import html2canvas from 'html2canvas-pro';
import jsPDF from 'jspdf';
import jsZip from 'jszip';
import ReactDomServer from 'react-dom/server';
import { saveAs } from 'file-saver';

import Modal from 'react-bootstrap/Modal';
import { useMsal } from '@azure/msal-react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function AssetsPage() {
    const gridRef = useRef<AgGridReact>(null);
    const navigate = useNavigate();
    const authFetch = useAuthFetch();
    const params = useParams();

    const { accounts } = useMsal();

    const [assetSuccess, setAssetSuccess] = useState<string | null>(null);
    const [ loading, setLoading ] = useState(true); 
    const [ buildingData, setBuildingData ] = useState([]);
    const [ assetData, setAssetData ] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ productData, setProductData ] = useState([]);
    const [ productFamiliesData, setProductFamiliesData] = useState([]);
    const [ templateItemsData, setTemplateItemsData] = useState([]);
    const [ rowChecked, setRowChecked] = useState([]);

    const [ modalQAChangeOpen, setModalQAChangeOpen] = useState(false); 
    const [modal, setModal] = useState({ show: false, title: "", newValue: "", data: [] });

    const [ inspectionPreviewOpen, setInspectionPreviewOpen] = useState(false);
    const [ inspectionAssetItemData, setInspectionAssetItemData] = useState([]);
    const [ inspectionData, setInspectionData] = useState(null);

    const [ buildingSelect, setBuildingSelect] = useState([]);

    let buildingId = params.buildingId || "";

    if(buildingId == "" && buildingData.length != 0) {
        buildingId = buildingData[0].id;
    }

    const requestData = async ()=> {
        setLoading(true);
        await requestAssetDataInspectionlatest(authFetch, buildingId).then(res => { return res; }).then((data) => { setAssetData(data); });
        setLoading(false);
    }

    const requestInitialData = async () => {
        await requestProductFamilyData(authFetch).then(res => { return res; }).then((data) => { setProductFamiliesData(data); });
        await requestProductData(authFetch).then(res => { return res; }).then((data) => { setProductData(data); });
        await requestBuildingData(authFetch, "slim").then(res => { return res; }).then((data) => {
            setBuildingData(data);
            if (buildingId == "" && data.length != 0) {
                buildingId = data[0].id;
                setBuildingSelect(data[0].name);
            } else {
                setBuildingSelect(data.find(it => it.id == buildingId).name);
            }
        });
        await requestAssetDataInspectionlatest(authFetch, buildingId).then(res => { return res; }).then((data) => { setAssetData(data); });
        await requestTemplateCheckedData(authFetch, true).then(res => { return res; }).then((data) => { setTemplateItemsData(data); });
        setLoading(false);
    }

    useEffect(() => { requestInitialData()}, [params.buildingId]);

    const handleClose = () => { setInspectionPreviewOpen(false); };
    const handleDialogOpen = () => { setDialogOpen(true); }
    const onBtnExport = useCallback(() => { gridRef.current!.api.exportDataAsCsv(); }, []);
    const onpdfExport = () => { downloadPdf(rowChecked); }


    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setDialogOpen(false);
    }

    const downloadPdf = async (assetItem) => {
        var zip = new jsZip();
        
        assetItem.map((item) => {
            requestLastInspectionData(authFetch, item.lastInspectionId).then(res => { return res; }).then((rInspectionData) => {
                const htmlString = ReactDomServer.renderToStaticMarkup(<InspectionPreview assetData={item} inspectionData={rInspectionData} pdfDownload="true" />);
                const iframe = document.createElement("iframe");
                document.body.appendChild(iframe);
                iframe.contentWindow.document.write(htmlString);

                html2canvas(iframe.contentWindow.document.body, { useCORS: true, allowTaint: true, scrollY: 0, scale:5 }).then(canvas => {
                    const jsPdf = createPDF(canvas);
                    iframe.remove();
                    canvas.remove();

                    if (assetItem.length === 1) {
                        jsPdf.save("inspection-" + item.id + ".pdf");
                    } else {
                        zip.file(`inspection- ${item.id}.pdf`, jsPdf.output('blob'));
                    }

                });
            });
        })

        if ((assetItem.length > 1)) {
            setTimeout(() => {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    saveAs(content, `inspectionReport.zip`);
                });
            }, 2000);
        }
 
    };

    const CustomInspectionLinkComponent = (props) => {
        if (props != null && props.data != null && props.data.lastInspectionId != null) {
            return (
                <Button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                    requestLastInspectionData(authFetch, props.data.lastInspectionId).then(res => { return res; }).then((rInspectionData) => {
                        setInspectionData(rInspectionData);
                        e.preventDefault();
                        setInspectionPreviewOpen(true);
                        setInspectionAssetItemData(props.data);
                    });
                }}>{moment(props.data.lastInspectionDate).format('YYYY-MM-DD')}</Button>
                /*<Link to={{ pathname: '/inspectionpreview/', }} state={{ assetData: props.data }}>{moment(props.data.lastInspectionDate).format('YYYY-MM-DD, h:mm:ss A')}</Link>*/ 
            )
        }
    };

    const handleChecked = async (assetId) => {
        let prev = rowChecked;
        let itemIndex = prev.indexOf(assetId);
        if (itemIndex !== -1) {
            prev.splice(itemIndex, 1);
        } else {
            prev.push(assetId);
        }
        setRowChecked([...prev]);
    }

    const qaChangeConfirm = async (params) => {
        if (params.newValue != "Cancel") {
            setModalQAChangeOpen(true);
        }
    }

    const cellClickEvent = async (params) => {

        let columnSelected = params.colDef.field.split('.')[0];
        
        if (params.newValue === "Cancel") {
            cancelQAChange(params);
            params.api.refreshCells({ columns: [columnSelected + ".completionDate"] });
        } else {
            const date = new Date();
            let statusType = 0;
            if (params.newValue === "Pass") {
                statusType = 1;
            } else if (params.newValue === "Fail"){
                statusType = 2;
            }

            const jsonArray = '{"qaStatus": "' + statusType +'", "completionDate": "' + date.toISOString() + '", "updatedBy": "' + accounts[0]?.name + '"}'
            let data = params.data;
            if (columnSelected === "techReview") {
                data.techReview = JSON.parse(jsonArray);
                params.data.qaApproval.completionDate = date;
            } else if (columnSelected === "qaReview") {
                data.qaReview = JSON.parse(jsonArray);
                params.data.qaReview.completionDate = date;
            } else if (columnSelected === "qaApproval") {
                data.qaApproval = JSON.parse(jsonArray);
                params.data.qaApproval.completionDate = date;
            }

            params.api.refreshCells({ columns: [columnSelected + ".completionDate"] });

            let url = getApiUrl("assets");
            url = `${url}/${params.data.id}`
     
             let fetchResponse = await authFetch("PUT", url, { ...data, buildingId: buildingId })
             if (fetchResponse.status != 409) {
                 console.log("UPDATED!");
             } else {
                 console.log("ERROR");
             }
        }
        closeQAModal();
    }

   function formatterCell(params: ValueFormatterParams) {

        let columnSelected = params.colDef.field.split('.')[0];
        let returnValue = "";
        if (params.data == null) {
            returnValue = params.value;
        } else {
            if (params.data != null && params.data.qaApproval != null && params.data.qaApproval.qaStatus != '0' && columnSelected === "qaApproval") {
                returnValue = params.value == null ? "" : moment(params.value).format('YYYY-MM-DD');
            } else if (params.data != null && params.data.qaReview != null && params.data.qaReview.qaStatus != '0' && columnSelected === "qaReview") {
                returnValue = params.value == null ? "" : moment(params.value).format('YYYY-MM-DD');
            } else if (params.data != null && params.data.techReview != null && params.data.techReview.qaStatus != '0' && columnSelected === "techReview") {
                returnValue = params.value == null ? "" : moment(params.value).format('YYYY-MM-DD');
            }
        }
        return returnValue;
    }

    const gridAssetLinkComponent = (props) => {
        return (
            <div style={{marginLeft:"-10px"} }>
                <Button type="button" className="btn btn-primary btn-sm" onClick={(ev) => {
                    ev.stopPropagation();
                    navigate(`/asset/${props.data.id}`)
                }}><i className="fa fa-external-link" />
                </Button>
            </div>
        )
    };

    const rowSelection = useMemo<RowSelectionOptions>(
        () => ({
            mode: 'multiRow',
            hideDisabledCheckboxes: true,
            isRowSelectable: (node) => (node.data ? node.data.lastInspectionId != null : false),
        }),
        []
    );

   const [colDefs, setColDefs] = useState([
        { headerName: "", suppressHeaderMenuButton: true, field: "id", maxWidth: 50, cellRenderer: gridAssetLinkComponent },
        { field: "assetType", maxWidth: 100 },
        { field: "creationDate", maxWidth: 150, sort: "desc" },
        { headerName: "Prod Code", field: "productCode", maxWidth: 150 },
        { field: "manufacturer", maxWidth: 125 },
        { field: "model", maxWidth: 100 },
        { headerName: "Serial Num", field: "serialNumber", maxWidth: 150 },
        { headerName: "Comm Serial Num", field: "commSerialNumber" },
        { headerName: "Se04 Require", field: "se04Required", maxWidth: 105, minWidth: 105 },
        { field: "status", maxWidth: 125 },
        { headerName: "Tech Review", field: "techReview.completionDate", maxWidth: 125 },
        { headerName: "QA Review", field: "qaReview.completionDate", maxWidth: 125 },
        { headerName: "QA Approval", field: "qaApproval.completionDate", maxWidth: 125 },
       /* {
            headerName: "Tech Review", field: "techReview.completionDate", stopEditingwhenGridLosesFocus: true,
            valueFormatter: formatterCell,
            cellClassRules: {
                'cellPass': (params) => { return params.data.techReview.qaStatus == '1';},
                'cellFail': (params) => { return params.data.techReview.qaStatus == '2';},
            },
            maxWidth: 150, minWidth: 150, cellEditor: 'agSelectCellEditor',
            onCellClicked: params => { params.event.preventDefault() },
            tooltipField: "techReview.updatedBy",
            cellEditorParams: {
                values: ['Cancel', 'Pass', 'Fail',],
            } as IRichCellEditorParams,
            onCellValueChanged: (e) => { openQAModal(e); }
        },
        {
            headerName: "QA Review", field: "qaReview.completionDate", stopEditingwhenGridLosesFocus: true,
            valueFormatter: formatterCell,
                    cellClassRules: {
                        'cellPass': (params) => { return params.data.qaReview.qaStatus == '1';},
                        'cellFail': (params) => { return params.data.qaReview.qaStatus == '2';},
                    },
            maxWidth: 150, minWidth: 150, cellEditor: 'agSelectCellEditor',
                    onCellClicked: params => { params.event.preventDefault() },
            tooltipField: "qaReview.updatedBy",
            cellEditorParams: {
                values: ['Cancel','Pass','Fail',],
            } as IRichCellEditorParams,
            onCellValueChanged: (e) => { openQAModal(e); }
        }, {
            headerName: "QA Approval", fieldqaApproval.completionDate: "", stopEditingwhenGridLosesFocus: true,
            valueFormatter: formatterCell,
            cellClassRules: {
                'cellPass': (params) => { return params.data.qaApproval.qaStatus == '1';},
                'cellFail': (params) => { return params.data.qaApproval.qaStatus == '2';},
            },
            maxWidth: 150, minWidth: 150, cellEditor: 'agSelectCellEditor',
            onCellClicked: params => { params.event.preventDefault() },
            tooltipField: "qaApproval.updatedBy",
            cellEditorParams: {
                values: ['Cancel', 'Pass', 'Fail',],
            } as IRichCellEditorParams,
            //onClick={openAudi}
            onCellValueChanged: (e) => { openQAModal(e); }
            //onCellValueChanged: (e) => { qaChangeConfirm(e); cellClickEvent(e); }
        },*/
        { headerName: "Inspection", minWidth: 125, maxWidth: 125, cellRenderer: CustomInspectionLinkComponent }
    ]);

   const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            minWidth: 100,
            //editable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        };
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const handleInspectionPreviewDialogClose = (status: string) => {
        if (status != "cancel") {
            requestData();
        }
    }

  const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const undo = useCallback(() => {
        gridRef.current!.api.undoCellEditing();
    }, []);

    const openQAModal = (e) => {
        if (e.newValue != "Cancel") {
            let columnSelected = e.colDef.field.split('.')[0];
            setModal({ show: true, title: columnSelected, newValue: e.newValue, data: e });
        } else {
            cancelQAChange(e);
        }
    };

    const closeQAModal = () => {
        setModal({ show: false, title: "", newValue: "", data: null });
    };

    const setModalBodyText = (newValue) => {
        return (
            newValue == "Fail" ?
                <span style={{ backgroundColor: "red", color: "white", fontWeight: "bold", padding: "4px" }}>{newValue}</span>
                :
                <span style={{ backgroundColor: "green", color: "white", fontWeight: "bold", padding: "4px" }}>{newValue}</span>
        )
    };

    function setModalTitle(titleValue) {
        let returnValue = "";
        if (titleValue === "techReview") {
            returnValue = "Tech Review";
        } else if (titleValue === "qaReview") {
            returnValue = "Tech Review";
        } else if (titleValue === "qaApproval") {
            returnValue = "Tech Review";
        }
        return returnValue;
    }

    const cancelQAChange = (e) => {
        e.newValue = e.oldValue;
        let columnSelected = e.colDef.field.split('.')[0];
        if (columnSelected === "techReview") {
            e.data.techReview.completionDate = e.oldValue;
        } else if (columnSelected === "qaReview") {
            e.data.qaReview.completionDate = e.oldValue;
        } else if (columnSelected === "qaApproval") {
            e.data.qaApproval.completionDate = e.oldValue;
        }
        e.api.refreshCells();
    };

    const submitToast = () => {
        if (assetSuccess === "POST") {
            assetAdded("Asset has been added");
            setAssetSuccess(null);
        } else if (assetSuccess === "PUT") {
            assetAdded("Asset has been Updated");
            setAssetSuccess(null);
        }
    }

    const assetAdded = (value: string) => { toast(value); };

    useEffect(() => { submitToast();}, [assetSuccess]);

    return (
        <>
        <ToastContainer />
        <LoadingTemplate isLoading={loading}>

            <div>
                <Button variant="primary" className="rounded-circle modalAddButton" onClick={handleDialogOpen}>+</Button>

                <div className="titleRow">
                    <div style={{flexGrow: '1'}}>Assets</div>
                    <div className="dropdown-container">
                        <div className="dropdown-label">Building</div>
                        <DropdownButton variant="outline-secondary" className="page-dropdown" id="dropdown-basic" title={buildingSelect}>
                            {buildingData.map((item) => (
                                <Dropdown.Item key={item.id} onClick={() => { setRowChecked([]); navigate(`/assets/${item.id}`); setBuildingSelect(item.name); }}>
                                    {item.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                </div>

                <div style={{ margin: '5px', height: "40px" }}>
                    <input type="text" id="filter-text-box" placeholder="Filter..." onInput={onFilterTextBoxChanged} style={{ height: '34px', float: "left", marginRight: "5px" }} />
                    <div className="dropdown-container check-action-container" style={{float: "left", width: "190px"}}>
                        {rowChecked && rowChecked.length > 0 &&
                            <DropdownButton variant="outline-secondary" title="Row Action">
                                <Dropdown.Item onClick={() => onpdfExport()}>Download PDF</Dropdown.Item>
                            </DropdownButton>
                        }
                    </div>
                    <Button type="button" className="btn btn-primary btn-sm" style={{ float: 'right', marginRight: '5px' }}>DOWNLOAD GRID&nbsp;&nbsp;<i className="fa fa-download" /></Button>
                </div>

                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact ref={gridRef} rowData={assetData} statusBar={statusBar} columnDefs={colDefs} defaultColDef={defaultColDef} sideBar={sideBar} rowSelection={rowSelection}
                        rowStyle={{ cursor: "pointer" }}
                        onRowSelected={(row) => {
                            handleChecked(row.data)
                        }
                    } />
                </div>

                    <CreateAssetDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit} assetSuccess={(successStatus) => setAssetSuccess(successStatus)}
                    buildingId={buildingId} productList={productData} productFamilies={productFamiliesData} defaultTemplates={templateItemsData} />

                <Modal show={inspectionPreviewOpen} size="xl">
                    <Modal.Body>
                        <div className="modalinspection">
                            <Button onClick={handleClose} type="button" className="btn btn-secondary">
                                Close
                            </Button>
                        </div>
                        <InspectionPreview onSubmit={handleInspectionPreviewDialogClose}
                            inspectionData={{ ...inspectionData }} assetData={...inspectionAssetItemData} pdfDownload="false" />
                    </Modal.Body>
                </Modal>

                <Modal className="modalStyle" show={modal.show} centered>
                    <Modal.Header>
                        <Modal.Title>{setModalTitle(modal.title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to change the {setModalTitle(modal.title)} value to {setModalBodyText(modal.newValue)} ?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { cellClickEvent(modal.data); }} >Yes</Button>
                        <Button variant="secondary" onClick={() => { cancelQAChange(modal.data); closeQAModal(); undo() }}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            
            </div>
            </LoadingTemplate>
        </>
    );
};


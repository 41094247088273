import React, { useEffect, useState } from "react";
import { getApiUrl } from "./authConfig";
import { useAuthFetch }  from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import moment from "moment";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import Multiselect from 'multiselect-react-dropdown';

import "./assets/css/modal.css";

import { requestTestBenchData } from "./hooks/TestBench";

import { SealingInfoObject } from "./common/types";
import { TestBenchObject } from "./common/types";
import { AssetObject } from "./common/types";

export type CreateSealingInfoDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: SealingInfoObject
    assetData?: AssetObject
} 

export const CreateSealingInfoDialog = observer((props: CreateSealingInfoDialogProps) => {
    const authFetch = useAuthFetch(); 
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
    const [testBenchData, setTestBenchData] = useState<TestBenchObject[]>([]);

    let isOpen = props.isOpen || false;

    const handleModalSuccessClose = () => { setModalSuccessOpen(false); handleClose() };

    var initialAssetData = props.assetData || {
        id: "",
        model: "",
        manufacturer: "",
        serialNumber: "",
    }

    var initialData = props.initialData || {
        meterConfiguration: "TwoPhaseNetwork",
        inspectionType: "Initial",
        clientName: "",
        certificateNumber: "",
        certificateNumberVersion: "0",
        workOrder: "",
        clientAddress: "",
        contractorNumber: "",
        approvalNumber: "",
        mcMeterType: "Energy",
        macAddress: "",
        ptSerialNumber: "N/A",
        inspectionNumber: "",
        meterFirmware: "",
        meterVoltage: "120",
        meterCurrent: "0.08",
        meterFrequency: "60",
        numMeteringPoints: "1",
        demandType: "N/A",
        multiplier: "1",
        demandInterval: "15",
        pulseOutputType: "N/A",
        electromechanical: "false",
        inspectedBy: "",
        inspectedDate: "",
        notes: "",
        accepted: false,
        sealExpiryYear: "",
        reviewedBy: "",
        parametersAltered: "",
        sealParameters: [],
        testBenchId: "",
    }

    const assetData = useLocalObservable(() => ({ 
        id: "",
        model: "",
        manufacturer: "",
        serialNumber: "",
    }))

    const formData = useLocalObservable(() => ({ 
        assetId: "",
        meterConfiguration: "TwoPhaseNetwork", 
        inspectionType: "Initial", 
        clientName: "",
        certificateNumber: "",
        certificateNumberVersion: "0",
        workOrder: "",
        clientAddress: "",
        contractorNumber: "",
        approvalNumber: "",
        mcMeterType: "Energy",
        macAddress: "",
        ptSerialNumber: "N/A",
        inspectionNumber: "",
        meterFirmware: "",
        meterVoltage: "120",
        meterCurrent: "0.08",
        meterFrequency: "60",
        numMeteringPoints: "1",
        demandType: "N/A",
        multiplier: "1",
        demandInterval: "15",
        pulseOutputType: "N/A",
        electromechanical: "false",
        inspectedBy: "",
        inspectedDate: moment(new Date()).format('YYYY-MM-DDT00:00:00.000'),
        notes: "",
        accepted: false,
        sealExpiryYear: "",
        reviewedBy: "",
        parametersAltered: "",
        sealParameters: [],
        testBenchId: "",
    }))

    const setInitialData = () => {
        assetData.id = initialAssetData.id;
        assetData.model = initialAssetData.model;
        assetData.manufacturer = initialAssetData.manufacturer;
        assetData.serialNumber = initialAssetData.serialNumber;

        formData.clientName = initialData.clientName;
        formData.meterFirmware = initialData.meterFirmware;
        formData.meterConfiguration = initialData.meterConfiguration;
        formData.inspectionType = initialData.inspectionType;
        formData.clientName = initialData.clientName;
        formData.certificateNumber = initialData.certificateNumber;
        formData.certificateNumberVersion = initialData.certificateNumberVersion;
        formData.workOrder = initialData.workOrder;
        formData.clientAddress = initialData.clientAddress;
        formData.contractorNumber = initialData.contractorNumber;
        formData.approvalNumber = initialData.approvalNumber;
        formData.mcMeterType = initialData.mcMeterType;
        formData.macAddress = initialData.macAddress;
        formData.ptSerialNumber = initialData.ptSerialNumber;
        formData.inspectionNumber = initialData.inspectionNumber;
        formData.meterFirmware = initialData.meterFirmware;
        formData.meterVoltage = initialData.meterVoltage;
        formData.meterCurrent = initialData.meterCurrent;
        formData.meterFrequency = initialData.meterFrequency;
        formData.numMeteringPoints = initialData.numMeteringPoints;
        formData.demandType = initialData.demandType;
        formData.multiplier = initialData.multiplier;
        formData.demandInterval = initialData.demandInterval;
        formData.pulseOutputType = initialData.pulseOutputType;
        formData.electromechanical = initialData.electromechanical;
        formData.inspectedBy = initialData.inspectedBy;
        formData.notes = initialData.notes;
        formData.accepted = initialData.accepted;
        formData.sealExpiryYear = initialData.sealExpiryYear;
        formData.reviewedBy = initialData.reviewedBy;
        formData.parametersAltered = initialData.parametersAltered;
        formData.sealParameters = initialData.sealParameters;
        formData.testBenchId = initialData.testBenchId;

        //inspectedDate: moment(new Date()).format('YYYY-MM-DDT00:00:00.000'),
            //sealParameters: [],
    }

    const dateFromDateString = (dateString) => {
        var date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return moment(date).format('YYYY-MM-DDT00:00:00.000');
    };

    const dateForPicker = (dateString) => {
        return moment(new Date(dateString)).format('YYYY-MM-DD')
    };

    useEffect(() => { setInitialData() }, [props.initialData]);

    useEffect(() => {
        requestTestBenchData(authFetch).then(res => { return res; }).then((data) => { setTestBenchData(data); });
    }, []);

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async () => {
        formData.assetId = assetData.id;
        let data = toJS(formData);
        console.log(data);

        let url = getApiUrl("sealingInfo");

        let fetchResponse = await authFetch("POST", url, {...data})

        if(fetchResponse.status != 409) {
            if(props.onSubmit != null) {
                props.onSubmit("success")
            }
        } else {
            setModalOpen(true)
        }
    }

    const meterConfigurationDrop = [
        { value: '1', name: 'Single Phase' },
        { value: '2', name: '2 Phase Network' },
        { value: '3', name: '3 Phase Delta' },
        { value: '4', name: '3 Phase' }
    ];

    const inspectionTypeDrop = [
        { value: '1', name: 'Initial' },
        { value: '2', name: 'Reverification' }
    ];

    const meterVoltageDrop = ["120", "208", "240", "277", "347", "480", "600"];
    const meterCurrentDrop = ["0.08", "0.1", "5"];
    const demandTypeDrop = ["N/A", "Block", "Rolling block / Sliding Window", "Exponential", "Other"];
    const pulseOutputTypeDrop = ["N/A", "Form A/KY/KZ (2-wire)", "Form C/KYZ (3-wire)", "Solid state (digital)", "Other"];

    const sealParametersDrop = [
        { name: 'Wh' },
        { name: 'W' },
        { name: 'Rec. Wh' },
        { name: 'Pulse' },
        { name: 'VAh (rms)' },
        { name: 'VA (rms)' },
        { name: 'Rec.VAh (rms)' },
        { name: 'VARh' },
        { name: 'VAR' },
        { name: 'Rec. VARh' }];

    const handleMultiChange = (event) => {
        formData.sealParameters = [];
        event.map(item => (
            formData.sealParameters.push(item.name)
        ));
    }

    function handleMultiSelect (event) {
        let sealParamArray = [];
        let sealParam = {};

        if (event != null) {
            event.map(item => (
                sealParam = { name: item },
                sealParamArray.push(sealParam)
            ));
        }

        return sealParamArray;
    }

    return (
        <Modal show={isOpen} size="xl">
            <Modal.Header>
                <Modal.Title>Create Sealing Info</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                {<div className="dropcontainer" style={{ marginBottom:"40px" }}>
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Test Bench</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.testBenchId == null || testBenchData.find(it => it.id == formData.testBenchId) == null ? "" : testBenchData.find(it => it.id == formData.testBenchId).name} >
                            {testBenchData.map((data) => {
                                return (
                                    <Dropdown.Item onClick={() => { formData.testBenchId = data.id }} key={data.id}>{data.name}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>}

                <div style={{ width: "33%", float: "left", display: "table", padding: "5px" }}>

                    <div style={{ width: "100%", color: "red", textAlign: "center" }}>MAIN</div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">QMC WO#</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.workOrder} onChange={e => formData.workOrder = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter MAC Address</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.macAddress} onChange={e => formData.macAddress = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Firmware</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.meterFirmware} onChange={e => formData.meterFirmware = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Element Configuration</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.meterConfiguration == null || meterConfigurationDrop.find(it => it.value == formData.meterConfiguration) == null ? "" : meterConfigurationDrop.find(it => it.value == formData.meterConfiguration).name}>
                                {meterConfigurationDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { formData.meterConfiguration = data.value }} key={data.value}>{data.name}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter Points</div>
                            <Form.Control className="modal-input" size="lg" type="number" onKeyPress={(e) => { formData.numMeteringPoints.length === 3 && e.preventDefault(); }} max={999} defaultValue={formData.numMeteringPoints} onChange={e => formData.numMeteringPoints = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Voltage (V)</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.meterVoltage == null || meterVoltageDrop.find(it => it == formData.meterVoltage) == null ? "" : meterVoltageDrop.find(it => it == formData.meterVoltage)}>
                                {meterVoltageDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { formData.meterVoltage = data }} key={data}>{data}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Current (A)</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.meterCurrent == null || meterCurrentDrop.find(it => it == formData.meterCurrent) == null ? "" : meterCurrentDrop.find(it => it == formData.meterCurrent)}>
                                {meterCurrentDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { formData.meterCurrent = data }} key={data}>{data}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Multiplier(s)</div>
                            <Form.Control className="modal-input" size="lg" type="number" defaultValue={formData.multiplier} onChange={e => formData.multiplier = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">PT S/N</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.ptSerialNumber} onChange={e => formData.ptSerialNumber = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Additional Information</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.notes} onChange={e => formData.notes = e.target.value} />
                        </div>
                    </div>

                </div>



                <div style={{ width: "33%", float: "left", display: "table", padding: "5px" }}>

                    <div style={{ width: "100%", color: "red", textAlign: "center" }}>Meter Owner Info</div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Certificate Number</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.certificateNumber} onChange={e => formData.certificateNumber = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Revision</div>
                            <Form.Control className="modal-input" size="lg" type="number" defaultValue={formData.certificateNumberVersion} onChange={e => formData.certificateNumberVersion = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter Owner</div>
                            <Form.Control className="modal-input" size="lg" type="text" />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter Owner Address</div>
                            <Form.Control className="modal-input" size="lg" type="text" />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Contractor Reg. No.</div>
                            <Form.Control className="modal-input" size="lg" type="number" defaultValue={formData.contractorNumber} onKeyPress={(e) => { formData.contractorNumber.length === 6 && e.preventDefault(); }} max={999999} onChange={e => formData.contractorNumber = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Contractor Inspection #</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.inspectionNumber} onChange={e => formData.inspectionNumber = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Conformance Status</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.accepted == null || [true, false].find(it => it == formData.accepted) == null ? "" : [true, false].find(it => it == formData.accepted).toString()}>
                                {[true, false].map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { formData.accepted = data }} key={data.toString()}>{data.toString()}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Inspection date</div>
                            <Form.Control type="date" size="lg" name="inspectionDate" value={dateForPicker(formData.inspectedDate)} onChange={e => formData.inspectedDate = dateFromDateString(e.target.value)} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Inspected by</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.inspectedBy} onChange={e => formData.inspectedBy = e.target.value} />
                        </div>
                    </div>


                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Reviewed by</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.reviewedBy} onChange={e => formData.reviewedBy = e.target.value} />
                        </div>
                    </div>

                </div>



                <div style={{ width: "33%", display: "table", padding: "5px" }}>

                    <div style={{ width: "100%", color: "red", textAlign: "center" }}>Regulatory</div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter Type</div>
                            <Form.Control className="modal-input" size="lg" type="text" defaultValue={formData.mcMeterType} onChange={e => formData.mcMeterType = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Inspection Type</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.inspectionType == null || inspectionTypeDrop.find(it => it.value == formData.inspectionType) == null ? "" : inspectionTypeDrop.find(it => it.value == formData.inspectionType).name}>
                                {inspectionTypeDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { formData.inspectionType = data.value }} key={data.value}>{data.name}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Notice of Approval</div>
                            <Form.Control className="modal-input" size="lg" defaultValue={formData.approvalNumber} type="text" onChange={e => formData.approvalNumber = e.target.value} />
                        </div>
                    </div>

                    {/*<div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Billing Parameters2</div>
                            <Select
                                isMulti
                                name="billingParams"
                                options={options2}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={event => handleMultiChange(event)}
                            />
                        </div>
                    </div>*/}



                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Billing Parameters</div>
                            <Multiselect options={sealParametersDrop} displayValue="name" selectedValues={handleMultiSelect(formData.sealParameters)} onSelect={event => handleMultiChange(event)} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Demand type</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.demandType == null || demandTypeDrop.find(it => it == formData.demandType) == null ? "" : demandTypeDrop.find(it => it == formData.demandType)}>
                                {demandTypeDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { formData.demandType = data }} key={data}>{data}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Intervals (min)</div>
                            <Form.Control className="modal-input" size="lg" type="number" defaultValue={formData.demandInterval} onChange={e => formData.demandInterval = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Pulse Type/Form</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.pulseOutputType == null || pulseOutputTypeDrop.find(it => it == formData.pulseOutputType) == null ? "" : pulseOutputTypeDrop.find(it => it == formData.pulseOutputType)}>
                                {pulseOutputTypeDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { formData.pulseOutputType = data }} key={data}>{data}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Parameters altered</div>
                            <Form.Control className="modal-input" size="lg" defaultValue={formData.parametersAltered} type="text" />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Due for reverification (year)</div>
                            <Form.Control className="modal-input" size="lg" type="number" defaultValue={formData.sealExpiryYear} onChange={e => formData.sealExpiryYear = e.target.value} />
                        </div>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleClose} className="btn btn-light">Cancel</button>
                <button onClick={handleSubmit} className="btn btn-light">Submit</button>
            </Modal.Footer>

            <Modal className="modalStyle" show={modalSuccessOpen} centered>
                <Modal.Header>
                    <Modal.Title>Sealing Info Submit</Modal.Title>
                </Modal.Header>
                <Modal.Body>The Sealing Info has been added, Click to close.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalSuccessClose}>Close</Button>
                </Modal.Footer>
            </Modal>

        </Modal>
    );
});

import React, { useEffect } from 'react'
import { useState } from "react";
import { LoadingTemplate } from '../LoadingTemplate';
import Button from 'react-bootstrap/Button';
import { useAuthFetch } from '../useAuthFetch';
import { getApiUrl } from '../authConfig';

export function DevPage() {
    const authFetch = useAuthFetch(); 
    const [bToken, setBtoken] = useState("");

    const setTheToken = () => {
        let token = "";
        if (localStorage.getItem("btkn") === null) {
            token = "Item Doesnt Exist";
        } else {
            token = localStorage.getItem('btkn');
        }
        setBtoken(token ? token : "No Token");
    }
    useEffect(() => { setTheToken() }, []);

    return (
            <div>
                <div style={{ flexGrow: '1' }} className="titleRow"><div>DEV PAGE</div></div>
            <Button variant="primary" onClick={() => { navigator.clipboard.writeText(bToken) }}>Copy bearer Token</Button>
                <div style={{ width: '50%', wordWrap: 'break-word' }}>{bToken}</div>
            </div>
    );
};
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import NavBarItem from './NavBarItem'
import { jwtDecode } from 'jwt-decode';

export type NavBarProps = {
    setSearchModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function NavBar(props: NavBarProps) {

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [userRoles, setUserRoles] = useState([]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (searchQuery != '') {
                navigate(`search/${searchQuery}`)
                setSearchQuery('');
            }
        }
    };

    const setRoles = () => {
        if (localStorage.getItem("btkn") != null) {
            setUserRoles(jwtDecode(localStorage.getItem("btkn")).roles);
        }
    }

    useEffect(() => { setRoles(); }, []);

    return (
        <>
            <div>
                <div className="sidebar  justify-content-center text-muted prevent-select">
                    <div style={{ height: "78px", background: "#343a40" }} >
                        <span id="menu-close">
                            <i className="fa-solid fa-xmark"></i>
                        </span>
                    </div>
                    <div className="navbar">
                        <li className="menu-item menu-item-searchbar">
                            <div className="has-search">
                                <span className="fa fa-search form-control-feedback form-control-sm"></span>
                                <input type="text" id="searchinput" className="form-control form-control-sm" placeholder="Search" onKeyDown={handleKeyDown} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                            </div>
                        </li>
                        <li className="menu-item menu-item-searchicon" style={{ display: "none" }} onClick={() => props.setSearchModalOpen(true)} >
                            <div className="menu-item-icon">
                                <i className="fa fa-search"></i>
                            </div>
                        </li>
                        <NavBarItem text="Dashboard" path="/dashboard" icon="fa-solid fa-house-user" />
                        <NavBarItem text="Assets" path="/assets" icon="fa-solid fa-microchip" />
                        <NavBarItem text="Buildings" path="/buildings" icon="fa-regular fa-building" />
                        <NavBarItem text="Products" path="/products" icon="fa-solid fa-book-open" />
                        <NavBarItem text="Templates" path="/templates" icon="fa-regular fa-rectangle-list" />
                        {userRoles.indexOf("Dev") > -1 ? <NavBarItem text="Test" path="/test" icon="fa-solid fa-code" /> : ""}
                        {userRoles.indexOf("Admin") > -1 ? <NavBarItem text="Dev" path="/dev" icon="fa-solid fa-code" /> : ""}
                        <NavBarItem text="QA" path="/qa" icon="fa-regular fa-rectangle-list" />

                        <div className="menu-item-spacer"></div>
                        <li className="menu-item menu-item-toggle">
                            <div className="menu-item-icon menu-item-toggle-icon">
                                <i className="fa fa-solid fa-angles-left"></i>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </>
    );
}


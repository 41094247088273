import React, {useState} from "react";
import { useAuthFetch }  from './useAuthFetch'
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import { minimalTimezoneSet } from 'compact-timezone-list';
import { getApiUrl } from "./authConfig";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./assets/css/modal.css";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';


export type CreateBuildingDialogData = {
    id: string,
    name: string, 
    address: string, 
    city: string, 
    province: string, 
    country: string, 
    postalCode: string, 
    latitude: number,
    longitude: number, 
    status: string, 
    timeZone: string
}

const statuses = ["Active", "Commissioning", "Predeployment", "Preinstallation", "Inactive", "Demo"];

export type CreateBuildingDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateBuildingDialogData
} 

export const CreateBuildingDialog = observer((props: CreateBuildingDialogProps)=> {
    const authFetch = useAuthFetch(); 

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null ? "" : props.initialData.id;

    var initialData = props.initialData || {
        name: "", 
        address: "", 
        city: "", 
        province: "", 
        country: "", 
        postalCode: "", 
        latitude: 0,
        longitude: 0, 
        status: "Inactive", 
        timeZone: "Europe/London"
    }

    const formData = useLocalObservable(()=> ({
        name: initialData.name, 
        address: initialData.address, 
        city: initialData.city, 
        province: initialData.province, 
        country: initialData.country, 
        postalCode: initialData.postalCode, 
        latitude: initialData.latitude,
        longitude: initialData.longitude, 
        status: initialData.status, 
        timeZone:initialData.timeZone
        
    }))

    const formError = useLocalObservable(()=> ({
        name: "", 
        address: "", 
        city: "", 
        province: "", 
        country: "", 
        postalCode: ""
        
    }))

    const clearErrors = ()=> {
        formError.name = ""; 
        formError.address = ""; 
        formError.city = ""; 
        formError.province = ""; 
        formError.country = ""; 
        formError.postalCode = ""; 
    }


    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async ()=> {

        let hasError = false;
        clearErrors();

        if(formData.name == "") {
            hasError = true; 
            formError.name = "Building name is required";  
        }

        if(formData.address == "") {
            hasError = true; 
            formError.address = "Building address is required";  
        }

        if(formData.city == "") {
            hasError = true; 
            formError.city = "Building city is required";  
        }

        if(formData.province == "") {
            hasError = true; 
            formError.province = "Building province is required";  
        }

        if(formData.country == "") {
            hasError = true; 
            formError.country = "Building country is required";  
        }

        if(formData.postalCode == "") {
            hasError = true; 
            formError.postalCode = "Building postalCode is required";  
        }

        if(hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT"; 
        let url = getApiUrl("buildings"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, data)
        if(props.onSubmit != null) {
            props.onSubmit("success")
        }
    } 

    const title = existingId == ""? "Create Building": "Update Building";

    return (
           
        <Modal show={isOpen} size="lg">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={{ marginBottom: '12px' }}>Please enter the information about the building</div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Name</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.name} onChange={e => formData.name = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Address</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.address} onChange={e => formData.address = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">City</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.city} onChange={e => formData.city = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Province</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.province} onChange={e => formData.province = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Country</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.country} onChange={e => formData.country = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Postal Code</div>
                        <Form.Control className="modal-input" size="lg" type="text" placeholder={formData.postalCode} onChange={e => formData.postalCode = e.target.value} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Latitude</div>
                        <Form.Control className="modal-input" size="lg" type="number" value={formData.latitude} onChange={e => formData.latitude = parseFloat(e.target.value)} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Longitude</div>
                        <Form.Control className="modal-input" size="lg" type="number" value={formData.longitude} onChange={e => formData.longitude = parseFloat(e.target.value)} />
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Status</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.status == null || statuses.find(it => it == formData.status) == null ? "" : statuses.find(it => it == formData.status)}>
                            {statuses.map((status) => {
                                return (
                                    <Dropdown.Item onClick={() => { formData.status = status }} key={status}>{status}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

                <div className="dropcontainer">
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Time Zone</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={formData.timeZone == null || minimalTimezoneSet.find(it => it.tzCode == formData.timeZone) == null ? "" : minimalTimezoneSet.find(it => it.tzCode == formData.timeZone).label}>
                            {minimalTimezoneSet.map((timeZone) => {
                                return (
                                    <Dropdown.Item onClick={() => { formData.timeZone = timeZone.tzCode }} key={timeZone.tzCode}>{timeZone.label}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button onClick={handleClose} className="btn btn-light">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} className="btn btn-light">
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
})

import React, { useCallback, useEffect, useRef, useMemo, Component } from 'react'
import { useState } from "react";
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import { getApiUrl } from '../authConfig';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import Button from 'react-bootstrap/Button';
import "../assets/css/formItems.css";

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef, GridState, ICellRendererParams, ValueFormatterParams } from "ag-grid-community";

import {ColDef,} from "ag-grid-community";

import { requestBuildingData } from "../hooks/Building";
import { requestProductFamilyData } from "../hooks/ProductFamily";
import { requestProductData } from "../hooks/Product";
import { requestLastInspectionData } from "../hooks/Inspection";
import { requestTemplateData } from "../hooks/Template";

import { useMsal } from '@azure/msal-react';

export function QAPage() {
    const [loading, setLoading] = useState(true);
    const [buildingData, setBuildingData] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [productData, setProductData] = useState([]);
    const [productFamiliesData, setProductFamiliesData] = useState([]);
    const [templateItemsData, setTemplateItemsData] = useState([]);
    const [rowChecked, setRowChecked] = useState([]);
    const [headerChecked, setHeaderChecked] = useState(false);
    const gridRef = useRef<AgGridReact>(null);

    const navigate = useNavigate();
    const authFetch = useAuthFetch();
    const params = useParams();

    const [buildingSelect, setBuildingSelect] = useState([]);

    let buildingId = params.buildingId || "";

    if (buildingId == "" && buildingData.length != 0) {
        buildingId = buildingData[0].id;
    }

    const requestAssetData = async () => {
        setAssetData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`assets/building/${buildingId}?building=true&latestInspection=true`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Asset Data Error: ' + error);
            } finally {
                setAssetData(responseData);
            }
        }
    }

    const requestInitialData = async () => {
        await requestProductFamilyData(authFetch).then(res => { return res; }).then((data) => { setProductFamiliesData(data); });
        await requestProductData(authFetch).then(res => { return res; }).then((data) => { setProductData(data); });
        await requestBuildingData(authFetch, "slim").then(res => { return res; }).then((data) => {
            setBuildingData(data);
            if (buildingId == "" && data.length != 0) {
                buildingId = data[0].id;
                setBuildingSelect(data[0].name);
            } else {
                setBuildingSelect(data.find(it => it.id == buildingId).name);
            }
        });
        await requestAssetData();
        await requestTemplateData(authFetch).then(res => { return res; }).then((data) => { setTemplateItemsData(data); });
        setLoading(false);
    }

    useEffect(() => { requestInitialData() }, [params.buildingId]);

    const CustomInspectionLinkComponent = (props) => {
        if (props != null && props.data != null && props.data.lastInspectionId != null) {
            return (
                <Button type="button" className="btn btn-primary btn-sm" onClick={() => navigate(`/roiview/${props.data.id}`)}>{moment(props.data.lastInspectionDate).format('YYYY-MM-DD')}</Button>
                /*<Link to={{ pathname: '/inspectionpreview/', }} state={{ assetData: props.data }}>{moment(props.data.lastInspectionDate).format('YYYY-MM-DD, h:mm:ss A')}</Link>*/
            )
        }
    };

    const gridAssetLinkComponent = (props) => {
        return (
            <div style={{ marginLeft: "-10px" }}>
                <Button type="button" className="btn btn-primary btn-sm" onClick={(ev) => {
                    ev.stopPropagation();
                    navigate(`/asset/${props.data.id}`)
                }}><i className="fa fa-external-link" />
                </Button>
            </div>
        )
    };

    const [colDefs, setColDefs] = useState([
        { headerName: "", suppressHeaderMenuButton: true, field: "id", maxWidth: 50, cellRenderer: gridAssetLinkComponent },
        { headerName: "Serial Num", field: "serialNumber", maxWidth: 150 },
        { field: "assetType", maxWidth: 200 },
        { field: "manufacturer", maxWidth: 200 },
        { headerName: "ROI Signed", minWidth: 125, maxWidth: 175, cellRenderer: CustomInspectionLinkComponent }
    ]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            minWidth: 100,
            editable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        };
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
            ]
        };
    }, []);

    const initialState = useMemo<GridState>(() => {
        return {
            rowSelection: ["2"],
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    return (
        <LoadingTemplate isLoading={loading}>
            <div>
                <div className="titleRow">
                    <div style={{ flexGrow: '1' }}>QA Summary</div>
                    <div className="dropdown-container">
                        <div className="dropdown-label">Building</div>
                        <DropdownButton variant="outline-secondary" className="page-dropdown" id="dropdown-basic" title={buildingSelect}>
                            {buildingData.map((item) => (
                                <Dropdown.Item key={item.id} onClick={() => { setRowChecked([]); navigate(`/qa/${item.id}`); setBuildingSelect(item.name); }}>
                                    {item.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                </div>

                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact ref={gridRef} rowData={assetData} statusBar={statusBar} columnDefs={colDefs} defaultColDef={defaultColDef} sideBar={sideBar}
                        rowStyle={{ cursor: "pointer" }} initialState={initialState} />
                </div>

            </div>
        </LoadingTemplate>
    );
};


import React, { useEffect } from 'react'
import { useState } from "react";
import { LoadingTemplate } from '../LoadingTemplate';
import ReactDOM from 'react-dom/client';
import { useAuthFetch } from '../useAuthFetch'

import { getProductGroupByAssetTypesData } from "../hooks/Product";

export function DashboardPage() {
    const [loading, setLoading] = useState(true);
    const [productData, setProductData] = useState([]);
    const authFetch = useAuthFetch();

    const requestInitialData = async () => {
        await getProductGroupByAssetTypesData(authFetch).then(res => { return res; }).then((data) => {
            setProductData(data);
        });
        setLoading(false);
    }

    useEffect(() => { 
        (async () => {
            requestInitialData();
        })();
    }, []);

    const [chartOptions, setChartOptions] = useState({
        data: getData(),
        title: {
            text: "Inspections Created",
        },
        series: [{ type: 'donut', calloutLabelKey: 'month', angleKey: 'data', innerRadiusRatio: 0.7, }],
    });

    function getData(){
          return [
              { month: 'Jan', avgTemp: 2.3, data: 162000 },
              { month: 'Mar', avgTemp: 6.3, data: 302000 },
              { month: 'May', avgTemp: 16.2, data: 800000 },
              { month: 'Jul', avgTemp: 22.8, data: 1254000 },
              { month: 'Sep', avgTemp: 14.5, data: 950000 },
              { month: 'Nov', avgTemp: 8.9, data: 200000 },
          ]
    }

    function getData2() {
        return [
            { "key": "Electricity","value": 151},
            { "key": "Water","value": 279},
            { "key": "Thermal", "value": 81 },
            { "key": "Gas", "value": 70 },
            { "key": "Communication", "value": 103 },
        ]
    }

    function getData4() {
        return [
            { "key": "Quebec", "value": 151 },
            { "key": "Ontario", "value": 279 },
            { "key": "Alberta", "value": 81 },
            { "key": "B.C.", "value": 70 }
        ]
    }

    function getData3() {
        let responseData = [];
        getProductGroupByAssetTypesData(authFetch).then(res => { return res; }).then((data) => {
            console.log(data);
            responseData = data;
        });
        return responseData;
    }

    const options5 = {
        data: getData2(),
        title: {
            text: "Assets",
        },
        subtitle: {
            text: "A List of Asset Types"
        },
        series: [{ type: 'bar', direction: "horizontal", xKey: 'key', yKey: 'value', }],
    }

    const options6 = {
        data: getData4(),
        title: {
            text: "Buildings",
        },
        subtitle: {
            text: "Buildings by Location"
        },
        series: [{
            type: 'bar', xKey: 'key', yKey: 'value' }],
    }

    return (
        <LoadingTemplate isLoading={loading}>
            <div>
                <div style={{ flexGrow: '1' }} className="titleRow"><div>DASHBOARD</div></div>
                <div style={{width:"100%", height:"400px"}}>
                    {/*<AgCharts options={options5} style={{ width: "400px", height: "400px", float: "left", marginLeft: "10px", outline: "1px solid blue" }} />
                    <AgCharts options={chartOptions} style={{ width: "400px", height: "400px", marginLeft: "10px", float: "left", outline: "1px solid blue" }} />
                    <AgCharts options={options6} style={{ width: "400px", height: "400px", float: "left", marginLeft: "10px", outline: "1px solid blue" }} />*/}
                </div>
                <hr/>
                <div>Latest Changes:</div>
            </div>
        </LoadingTemplate>
    );
};
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";



export function useAuthFetch() {
    const { instance, accounts } = useMsal();

    return (method: string, endpoint: string, data = null, contentType = 'application/json'): Promise<Response> => {
        return instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        })
            .then((response) => {

                // for form-data, the brower will set the content type, so do not include it
                const headers = (contentType != 'multipart/form-data') ?
                    new Headers({ 'content-type': contentType }) : new Headers();

                const bearer = `Bearer ${response.accessToken}`;
                headers.append("Authorization", bearer);
                localStorage.setItem('btkn', response.accessToken);

                const body = (contentType == 'application/json') ?
                    (data ? JSON.stringify(data) : null)
                    : data;


                let options = {
                    method: method,
                    headers: headers,
                    body: body
                };

                return fetch(endpoint, options);

            });
    }

} 
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useState } from "react";
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateAssetDialog } from '../CreateAssetDialog';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {
    ColDef,
    ColGroupDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    RowSelectionMode,
    RowSelectionOptions,
    createGrid,
} from "ag-grid-community";


import { requestAssetData } from "../hooks/Asset";
import { requestProductFamilyData } from "../hooks/ProductFamily";
import { requestProductData } from "../hooks/Product";
import { requestBuildingData } from "../hooks/Building";

import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import Button from 'react-bootstrap/Button';

/*import "./styles.css";*/
import "../assets/css/formItems.css"
export function TestPage() {
    const gridRef = useRef<AgGridReact>(null);
    const navigate = useNavigate();
    const authFetch = useAuthFetch();
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [buildingData, setBuildingData] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [productData, setProductData] = useState([]);
    const [productFamiliesData, setProductFamiliesData] = useState(null);
    const [buildingSelect, setBuildingSelect] = useState([]);

    let buildingId = params.buildingId || "";

    if (buildingId == "" && buildingData.length != 0) {
        buildingId = buildingData[0].id;
    }

    const requestData = async () => {
        setLoading(true);
        await requestAssetData(authFetch, { buildingId }).then(res => { return res; }).then((data) => {
            setAssetData(data);
        });
        setLoading(false);
    }

    const requestInitialData = async () => {
        await requestProductFamilyData(authFetch).then(res => { return res; }).then((data) => { setProductFamiliesData(data); });
        await requestProductData(authFetch).then(res => { return res; }).then((data) => { setProductData(data); });
        await requestBuildingData(authFetch, "").then(res => { return res; }).then((data) => {
            setBuildingData(data);
            if (buildingId == "" && data.length != 0) {
                buildingId = data[0].id;
                setBuildingSelect(data[0].name);
            } else {
                setBuildingSelect(data.find(it => it.id == buildingId).name);
            }
        });
        await requestAssetData(authFetch, { buildingId }).then(res => { return res; }).then((data) => {
            setAssetData(data);
        });

        setLoading(false);
    }

    useEffect(() => { requestInitialData() }, [params.buildingId]);

    const handleDialogOpen = () => { setDialogOpen(true); }

    const handleDialogSubmit = (status: string) => {
        if (status != "cancel") {
            requestData();
        }
        setDialogOpen(false);
    }

    const rowSelection = useMemo<
        RowSelectionOptions | "single" | "multiple"
    >(() => {
        return {
            mode: "multiRow",
        };
    }, []);

    const [colDefs, setColDefs] = useState<ColDef[]>([
        { field: "assetType", enableRowGroup: true, maxWidth: 150 },
        { field: "productCode", enableRowGroup: true, maxWidth: 175 },
        { field: "manufacturer", enableRowGroup: true, maxWidth: 125 },
        { field: "model", enableRowGroup: true, maxWidth: 150 },
        { headerName: "Status", field: "status", maxWidth: 175 }
       
    ]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            editable: true,
        }
    }, []);


    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const onCsvExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    const onExcelExport = useCallback(() => {
        gridRef.current!.api.exportDataAsExcel();
    }, []);

    return (
        <LoadingTemplate isLoading={loading}>
            <div>
                <Button variant="primary" className="rounded-circle modalAddButton" onClick={handleDialogOpen}>+</Button>

                <div className="titleRow">
                    <div style={{ flexGrow: '1' }}>This is a Dev Testing Page</div>
                    <div className="dropdown-container">
                        <div className="dropdown-label">Building</div>
                        <DropdownButton variant="outline-secondary" className="page-dropdown" id="dropdown-basic" title={buildingSelect}>
                            {buildingData.map((item) => (
                                <Dropdown.Item key={item.id} onClick={() => { navigate(`/test/${item.id}`); setBuildingSelect(item.name); }}>
                                    {item.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col col-8 col-lg-3 col-sm-6">
                        <input type="text" id="filter-text-box" className="form-control" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                    </div>
                    <div className="col">
                        <i className="fa-solid fa-file-excel fa-2x me-2 clickable hoverable" onClick={onExcelExport} style={{ lineHeight: '1.25em' }}></i>
                        <i className="fa-solid fa-file-csv fa-2x clickable hoverable" onClick={onCsvExport}></i>
                    </div>
                    {/*<Button style={{ float: 'right', marginRight: '5px' }} size="small" variant="contained" endIcon={<DownloadForOfflineIcon />} onClick={onBtnExport}>Download Grid</Button>*/}
                </div>

                <div className="ag-theme-quartz" style={{ height: 600 }}>

                    <AgGridReact ref={gridRef} rowData={assetData} statusBar={statusBar} columnDefs={colDefs} defaultColDef={defaultColDef} rowSelection={rowSelection} />
                    {/*<AgGridReact ref={gridRef} rowData={assetData} statusBar={statusBar} columnDefs={colDefs} defaultColDef={defaultColDef} />*/}

                    {/*<AgGridReact ref={gridRef} columnDefs={colDefs} defaultColDef={defaultColDef} rowData={assetData} />*/}
                </div>

                <CreateAssetDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit}
                    buildingId={buildingId} productList={productData}
                    productFamilies={productFamiliesData}
                />
            </div>
        </LoadingTemplate>
    );
};
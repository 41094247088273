import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { useState } from "react";
import { CreateBuildingDialog } from '../CreateBuildingDialog'
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import { getApiUrl } from '../authConfig';
import { useNavigate } from 'react-router-dom';

import { requestBuildingData } from "../hooks/Building";

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef, } from "ag-grid-community";

import Button from 'react-bootstrap/Button';
import "../assets/css/formItems.css"

export function BuildingsPage() {
    const [ buildingData, setBuildingData ] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const navigate = useNavigate();
    const authFetch = useAuthFetch(); 
    const gridRef = useRef<AgGridReact>(null);

    const requestInitialData = async () => {
        await requestBuildingData(authFetch, "").then(res => { return res; }).then((data) => { setBuildingData(data); });
    }

    useEffect(() => {
        requestInitialData();
    }, []);

    const handleDialogOpen = () => {
        setDialogOpen(true); 
    }

    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestInitialData(); 
        }
        setDialogOpen(false);
    }

    const CustomButtonComponent = (props) => {
        return (
            <div style={{ marginLeft: "-10px" }}>
                <Button type="button" className="btn btn-primary btn-sm" onClick={(ev) => {
                    ev.stopPropagation();
                    navigate(`/assets/${props.data.id}`)
                }}>
                    Assets
                    &nbsp;&nbsp;<i className="fa fa-bolt" />
                </Button>
            </div>
        )
    };

    const [colDefs, setColDefs] = useState([
        { headerName: "Building Name", field: "name" },
        { headerName: "Street Address", field: "address" },
        { field: "city" },
        { field: "province" },
        { field: "status" },
        { field: "Actions", maxWidth: 125, cellRenderer: CustomButtonComponent }
    ]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const onBtnExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);
 

    return (
        <LoadingTemplate isLoading={ buildingData.length == 0}>
            <div>
                <Button variant="primary" className="rounded-circle modalAddButton" onClick={handleDialogOpen}>+</Button>
                <div className="titleRow">
                    <div style={{ flexGrow: '1' }}>Buildings</div>
                </div>

                <div style={{ margin: '5px' }}>
                    <input type="text" id="filter-text-box" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                    <Button type="button" className="btn btn-primary btn-sm" style={{ float: 'right', marginRight: '5px' }} onClick={onBtnExport}>DOWNLOAD GRID&nbsp;&nbsp;<i className="fa fa-download" /></Button>
                </div>

                <div className="ag-theme-quartz" style={{ height: 700 }}>
                    <AgGridReact ref={gridRef} rowData={buildingData} statusBar={statusBar} sideBar={sideBar} columnDefs={colDefs} rowStyle={{ cursor: "pointer" }} onRowClicked={(e) =>
                        navigate(`/building/${e.data.id}`)
                    } />
                </div>

                <CreateBuildingDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit}/>
            
            </div>
        </LoadingTemplate>
       
    );
};


import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { useState } from "react";
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import { getApiUrl } from '../authConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateProductFamilyDialog } from '../CreateProductFamilyDialog';
import { AgGridReact } from 'ag-grid-react';

import { requestProductFamilyData } from "../hooks/ProductFamily";
import { requestTemplateCheckedData } from "../hooks/Template";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef, } from "ag-grid-community";

import Button from 'react-bootstrap/Button';
import "../assets/css/formItems.css"
import Form from 'react-bootstrap/Form';

export function ProductFamilyPage() {
    const [ loading, setLoading ] = useState(true); 
    const [productFamiliesData, setProductFamiliesData] = useState([]);
    const [templateItemsData, setTemplateItemsData] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogInitialData, setDialogInitialData ] = useState(null);
    const authFetch = useAuthFetch(); 
    const params = useParams(); 
    const gridRef = useRef<AgGridReact>(null);

    const requestData = async ()=> {
        await requestProductFamilyData(authFetch).then(res => { return res; }).then((data) => { setProductFamiliesData(data); });
        await requestTemplateCheckedData(authFetch, true).then(res => { return res; }).then((data) => {
            const sorted = data.sort((a, b) => { return a.name > b.name ? 1 : -1 })
                .map(({ id, name }) => { return { id, name } })
            setTemplateItemsData(sorted);
        });
        setLoading(false);
    }

    useEffect(() => { requestData(); }, []);


    const handleDialogOpen = (initData = null) => {
        setDialogInitialData(initData);
        setDialogOpen(true); 
    }

    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setDialogOpen(false);
    }

    const CustomButtonComponent = (props) => {
        if (props != null && props.data != null) {
            return (
                <div style={{ marginLeft: "-10px" }}>
                    <Button type="button" className="btn btn-primary btn-sm" onClick={(ev) => {
                        ev.stopPropagation();
                        handleDialogOpen({ ...props.data });
                    }}>
                        Edit
                        &nbsp;&nbsp;<i className="fa fa-edit" />
                    </Button>
                </div>
            )
        }
    };

    const [colDefs, setColDefs] = useState([
        { field: "name" },
        { field: "category" },
        { field: "manufacturer" },
        { field: "defaultTemplate" },
        { field: "Actions", cellRenderer: CustomButtonComponent }
    ]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const onBtnExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    return (
        <LoadingTemplate isLoading={loading}>
            <div>
                <Button variant="primary" className="rounded-circle modalAddButton" onClick={handleDialogOpen}>+</Button>
                <div className="titleRow"><div>Product Family</div></div>

                <div style={{ margin: '5px' }}>
                    <input type="text" id="filter-text-box" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                    <Button type="button" className="btn btn-primary btn-sm" style={{ float: 'right', marginRight: '5px' }} onClick={onBtnExport}>DOWNLOAD GRID&nbsp;&nbsp;<i className="fa fa-download" /></Button>
                </div>

                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact ref={gridRef} rowData={productFamiliesData} columnDefs={colDefs} sideBar={sideBar} statusBar={statusBar} />
                </div>

                <CreateProductFamilyDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit} initialData={dialogInitialData} productFamilies={productFamiliesData} defaultTemplates={templateItemsData} />
            
            </div>
        </LoadingTemplate>
    );
};

